import { Grid, IconButton, InputAdornment, Stack } from "@mui/material";
import "./styles.scss";
import React, { useState, useEffect } from "react";
import CustomDatePicker from "../../../../components/UI/DatePicker/CustomDatePicker";
import CustomTextField from "../../../../components/UI/CustomTextInput";
import CustomSelect from "../../../../components/UI/CustomSelect";
import { CustomButton } from "../../../../components/UI/CustomButton";
import { useForm } from "react-hook-form";
import { getbarbers } from "../../services/barbers.services";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const selectOptions = [
  {
    title: "Suzi",
    value: "Suzi",
  },
  {
    title: "Sam",
    value: "Sam",
  },
  {
    title: "Elisa",
    value: "Elisa",
  },
];

const CustomForm = ({ id, barbero, create, update }) => {
  const { handleSubmit, control, setValue, reset } = useForm();
  const navigate = useNavigate();

  const [barber, setBarber] = useState({});

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    barbero === undefined ? create(data) : update({ ...data, id: Number(id) });
    navigate("/barbers/all");
  };

  const resetForm = () => {
    reset({});
  };

  useEffect(() => {
    if (barbero !== undefined) {
      setValue("name", barbero.name);
      setValue("lastname", barbero.lastname);
      setValue("documentNumber", barbero.documentNumber);
      setValue("email", barbero.email);
      setValue("password", barbero.password);
    }
  }, [setValue, barbero]);

  return (
    <div className="barbers-form-main-container">
      <Box className="barbers-form-grid-container">
        <Grid container spacing={2} className={"barbers-form-grid"}>
          <Grid container item spacing={2} className="barbers-inner-grid">
            <Grid item xs={12} md={6} className={"grid-item-xs"}>
              <div className="barbers-inner-grid-inputs">
                <CustomSelect
                  name="barberia"
                  control={control}
                  label="Barberia"
                  options={selectOptions}
                  rules={{
                    required: {
                      value: true,
                      message: "Campo obligatorio",
                    },
                  }}
                />
              </div>
            </Grid>
            {/* <Grid item xs={12} md={6} className={"grid-item-xs"}>
              <div className="barbers-inner-grid-inputs">
                <div className="date-container">
                  {console.log(barber.created_at)}
                  <CustomDatePicker
                    name="created_at"
                    control={control}
                    label="Fecha de Registro"
                    value={barber !== undefined && barber.created_at}
                  />
                </div>
              </div>
            </Grid> */}
          </Grid>
          <div className="barbers-grid-item-container">
            <Grid item xs={12} md={12} mb={3} className="barbers-grid-item">
              <CustomTextField
                name="name"
                control={control}
                label="Nombre"
                value={barber !== undefined ? barber.name : ""}
                type={"text"}
                placeholder={"Lorem ipsum"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="barbers-grid-item-container">
            <Grid item xs={12} md={12} mb={3} className="barbers-grid-item">
              <CustomTextField
                name="lastname"
                control={control}
                label="Apellido"
                value={barber !== undefined ? barber.lastname : ""}
                type={"text"}
                placeholder={"Lorem ipsum"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>

          {/* <div className="barbers-grid-item-container">
            <Grid item xs={12} md={12} mb={3} className="barbers-grid-item">
              <CustomTextField
                name="comision"
                control={control}
                label="Comisión"
                value={barber !== undefined ? barber.commission : ""}
                numeric={true}
                placeholder={"%***"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>
          </div> */}

          <div className="barbers-grid-item-container">
            <Grid item xs={12} md={12} mb={3} className="barbers-grid-item">
              <CustomTextField
                name="documentNumber"
                control={control}
                label="DNI"
                value={barbero !== undefined ? barbero.documentNumber : ""}
                type={"text"}
                placeholder={"DNI sin puntos ni comas"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="barbers-grid-item-container">
            <Grid item xs={12} md={12} mb={3} className="barbers-grid-item">
              <CustomTextField
                name="email"
                control={control}
                label="Email"
                value={barber !== undefined ? barber.email : ""}
                type={"email"}
                placeholder={"abc123@abc123.com"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="barbers-grid-item-container">
            <Grid item xs={12} md={12} mb={3} className="barbers-grid-item">
              <CustomTextField
                name="password"
                control={control}
                label="Contraseña"
                value={barbero !== undefined ? barbero.password : ""}
                type={showPassword ? "text" : "password"}
                placeholder={"*********"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDown}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                rules={{
                  required: {
                    value: false,
                    message: "Campo no obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <Grid
            item
            xs={12}
            md={12}
            mb={3}
            className={"barbers-form-buttons-container"}
          >
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "row",
              }}
              spacing={3}
              className="barbers-form-buttons-stack"
            >
              <CustomButton
                onClick={handleSubmit(onSubmit)}
                variant={"text"}
                textColor={"black"}
              >
                Submit
              </CustomButton>
              <CustomButton
                onClick={resetForm}
                variant={"text"}
                textColor={"black"}
              >
                Reset
              </CustomButton>
            </Stack>
            <div className="barbers-form-back-button-container">
              <CustomButton
                onClick={() => navigate(-1)}
                variant="outlined"
                color="#FFC107"
                textColor={"black"}
              >
                Volver
              </CustomButton>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CustomForm;
