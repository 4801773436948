import PrivateRoute from "../utils/navigation";

/* Owners Components */
import OwnersHome from "../modules/owners/views/OwnersHome/Index";
import OwnerCreate from "../modules/owners/views/OwnersCreate/Index";
import OwnersDetail from "../modules/owners/views/OwnersDetail/Index";
import EditOwner from "../modules/owners/views/OwnersEdit/Index";
import DeleteOwner from "../modules/owners/views/OwnersDelete/Index";

/* Componentes de barberos */
import BarbersHome from "../modules/barbers/views/BarbersHome/Index";
import BarberCreate from "../modules/barbers/views/BarbersCreate/index";
import BarbersDetail from "../modules/barbers/views/BarbersDetail/index";
import EditBarber from "../modules/barbers/views/BarbersEdit/index";
import DeleteBarber from "../modules/barbers/views/BarbersDelete/index";

/* Componentes de Barberias */
import BarberShopsHome from "../modules/barbershops/views/BarberShopsHome";
import BarberShopsCreate from "../modules/barbershops/views/BarberShopsCreate";
import BarberShopsDetail from "../modules/barbershops/views/BarberShopsDetail";
import BarberShopsEdit from "../modules/barbershops/views/BarberShopsEdit";
import BarberShopsDelete from "../modules/barbershops/views/BarberShopsDelete";

/* Componentes de Tarifas */
import RatesHome from "../modules/rates/views/RatesHome";
import FilteredRates from "../modules/rates/views/FilteredRates";
import RatesCreate from "../modules/rates/views/RatesCreate";
import RatesDetail from "../modules/rates/views/RatesDetail";
import RatesEdit from "../modules/rates/views/RatesEdit";
import RatesDelete from "../modules/rates/views/RatesDelete";

/* Componentes de Vendedores */
import SellersHome from "../modules/sellers/views/SellersHome";
import SellerCreate from "../modules/sellers/views/SellersCreate";
import SellerDetail from "../modules/sellers/views/SellersDetail";
import SellerEdit from "../modules/sellers/views/SellersEdit";
import SellerDelete from "../modules/sellers/views/SellersDelete";

/* Componentes de Facturación */
import BillsDashboard from "../modules/billing/views/BillsDashboard";

/* Componentes de Clientes */
import CustomersHome from "../modules/customers/views/CustomersHome/index";

/* Rutas de Owners */
export const OWNERS_ALL = {
  path: "/owners/all",
  element: <PrivateRoute Component={OwnersHome} />,
};
export const OWNERS_ADD = {
  path: "/owners/add",
  element: <PrivateRoute Component={OwnerCreate} />,
};
export const OWNERS_DETAIL = {
  path: "/owners/detail/:id",
  element: <PrivateRoute Component={OwnersDetail} />,
};

export const OWNER_EDIT = {
  path: "/owners/edit/:id",
  element: <PrivateRoute Component={EditOwner} />,
};
export const OWNER_DELETE = {
  path: "/owners/delete/:id",
  element: <PrivateRoute Component={DeleteOwner} />,
};

/* Rutas de Barbers */

export const BARBERS_ALL = {
  path: "/barbers/all",
  element: <PrivateRoute Component={BarbersHome} />,
};

export const BARBERS_ADD = {
  path: "/barbers/add",
  element: <PrivateRoute Component={BarberCreate} />,
};

export const BARBERS_DETAIL = {
  path: "/barbers/detail/:id",
  element: <PrivateRoute Component={BarbersDetail} />,
};

export const BARBERS_EDIT = {
  path: "/barbers/edit/:id",
  element: <PrivateRoute Component={EditBarber} />,
};

export const BARBERS_DELETE = {
  path: "/barbers/delete/:id",
  element: <PrivateRoute Component={DeleteBarber} />,
};

/* Rutas de Barberías */

export const BARBERSHOPS_ALL = {
  path: "/barbershops/all",
  element: <PrivateRoute Component={BarberShopsHome} />,
};

export const BARBERSHOPS_ADD = {
  path: "/barbershops/add",
  element: <PrivateRoute Component={BarberShopsCreate} />,
};

export const BARBERSHOPS_DETAIL = {
  path: "/barbershops/detail/:id",
  element: <PrivateRoute Component={BarberShopsDetail} />,
};

export const BARBERSHOPS_EDIT = {
  path: "/barbershops/edit/:id",
  element: <PrivateRoute Component={BarberShopsEdit} />,
};

export const BARBERSHOPS_DELETE = {
  path: "/barbershops/delete/:id",
  element: <PrivateRoute Component={BarberShopsDelete} />,
};

/* Rutas de tarifas */

export const RATES_ALL = {
  path: "/rates/all",
  element: <PrivateRoute Component={RatesHome} />,
};

export const RATES_FILTERED = {
  path: "/rates/filter/:day/:shift",
  element: <PrivateRoute Component={FilteredRates} />,
};

export const RATES_ADD = {
  path: "/rates/add",
  element: <PrivateRoute Component={RatesCreate} />,
};

export const RATES_DETAIL = {
  path: "/rates/detail/:id",
  element: <PrivateRoute Component={RatesDetail} />,
};

export const RATES_EDIT = {
  path: "/rates/edit/:id",
  element: <PrivateRoute Component={RatesEdit} />,
};

export const RATES_DELETE = {
  path: "/rates/delete/:id",
  element: <PrivateRoute Component={RatesDelete} />,
};

/* Rutas de Vendedores */

export const SELLERS_ALL = {
  path: "/sellers/all",
  element: <PrivateRoute Component={SellersHome} />,
};

export const SELLERS_ADD = {
  path: "/sellers/add",
  element: <PrivateRoute Component={SellerCreate} />,
};

export const SELLERS_DETAIL = {
  path: "/sellers/detail/:id",
  element: <PrivateRoute Component={SellerDetail} />,
};

export const SELLERS_EDIT = {
  path: "/sellers/edit/:id",
  element: <PrivateRoute Component={SellerEdit} />,
};

export const SELLERS_DELETE = {
  path: "/sellers/delete/:id",
  element: <PrivateRoute Component={SellerDelete} />,
};

/* Rutas de Facturación */

export const BILLS_HOME = {
  path: "/billing",
  element: <PrivateRoute Component={BillsDashboard} />,
};

/* Rutas de Clientes */

export const CUSTOMERS_HOME = {
  path: "/clients/all",
  element: <PrivateRoute Component={CustomersHome} />,
};
