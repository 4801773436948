import React from "react";
import { CustomButton } from "../../../../components/UI/CustomButton";
import "./styles.scss";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";

const NoDataCard = () => {
  const navigate = useNavigate();

  return (
    <div className="no-data-card-main-container">
      <Card className="no-data-card" sx={{ minWidth: 300 }} elevation={4}>
        <CardContent className="no-data-card-content">
          <Typography variant="h5" component="div">
            Todavía no hay tarifas para mostrar
          </Typography>
        </CardContent>
        <CardContent className="no-data-card-buttons-container">
          <Stack
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
            }}
            spacing={3}
            className="no-data-card-buttons"
          >
            <CustomButton
              variant={"text"}
              textColor="black"
              onClick={() => navigate(-1)}
              icon={<ArrowBackIcon />}
            >
              Volver
            </CustomButton>
            <CustomButton
              variant={"text"}
              textColor="black"
              onClick={() => navigate("/rates/add")}
              icon={<AddIcon />}
            >
              Agregar Ahora
            </CustomButton>
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
};

export default NoDataCard;
