import axios from "axios";

export const HttpCreateBarberGateway = () => {
  return {
    createBarber: async (data) => {
      const { password, email, name, lastname, documentNumber } = data;
      const body = { password, email, name, lastname, documentNumber };

      return axios.post(`http://45.90.223.78/test/onbording/register`, body);
    },
  };
};
