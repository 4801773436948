export const SellersDeletePresenter = (deleteSellerGateway, viewHandlers) => {
  return {
    present() {
      console.log("SellersDelete was rendered");
    },
    deleteSeller(id) {
      const deleteSellerPromise = deleteSellerGateway.deleteSeller(id);

      deleteSellerPromise
        .then((data) => {
          viewHandlers.deleteSellerSucces(data);
        })
        .catch((err) => {
          viewHandlers.deleteSellerError(err);
        });
    },
  };
};
