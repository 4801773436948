export const SellersDetailPresenter = (getSellerGateway, viewHandlers) => {
  return {
    present() {
      console.log("SellerDetail was rendered");
    },
    getSeller(id) {
      const getSellerPromise = getSellerGateway.getSeller(id);

      getSellerPromise
        .then((data) => {
          viewHandlers.getSellerSucces(data);
        })
        .catch((err) => {
          viewHandlers.getSellerError(err);
        });
    },
  };
};
