import axios from "axios";

export const HttpDeleteSellerGateway = () => {
  return {
    deleteSeller: async (id) => {
      return axios.delete(
        `http://45.90.223.78/test/business/seller/delete/id/${id}`
      );
    },
  };
};
