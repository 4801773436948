import { Divider, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import CustomForm from "../../components/CustomForm";
import { getSellersEditPresenter } from "../../infrastructure/presentation/presenterProvider";

const SellerEdit = () => {
  const { id } = useParams();
  const [seller, setSeller] = useState({});

  const viewHandlers = {
    getSellerSucces(data) {
      setSeller(data.data.data);
    },
    getSellerError(err) {
      console.log("ERROR: ", err);
    },
    updateSellerSucces(data) {
      console.log("Updated Seller: ", data);
    },
    updateSellerError(err) {
      console.log("ERROR: ", err);
    },
  };

  const presenter = getSellersEditPresenter(viewHandlers);

  const editSeller = (data) => {
    presenter.updateSeller(data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getSeller(id);
  }, []);

  return (
    <Paper elevation={8} className={"sellersEdit-form-background"}>
      <div className={"sellersEdit-form-title-container"}>
        <div className={"sellersEdit-form-title"}>Editar Vendedor</div>
        <div>
          <Divider variant="middle" color={"black"} />
        </div>
      </div>
      <div className="sellersEdit-customForm-container">
        <CustomForm id={id} update={editSeller} sellerObject={seller} />
      </div>
    </Paper>
  );
};

export default SellerEdit;
