import React from "react";
import "./styles.scss";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Stack } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";

function DataTable({ category, rows, columns, buttonTitle }) {
  function CustomToolbar() {
    return (
      <div className="custom-toolbar-container">
        <GridToolbarContainer className="grid-toolbar-container">
          <Stack
            direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
            spacing={{ xs: 3, sm: 3 }}
            className="toolbar-items-container"
          >
            <div className="toolbar-item-btn">
              <Link to={`/${category}/add`} className="category-link">
                <button className="btt1" color="success">
                  <AddIcon />
                  {buttonTitle}
                </button>
              </Link>
            </div>

            <div className="toolbar-item-search">
              <GridToolbarQuickFilter className="search-bar" />
            </div>
          </Stack>
        </GridToolbarContainer>
      </div>
    );
  }

  return (
    <div className={"data-grid-container"}>
      <DataGrid
        className="data-table"
        rows={rows}
        columns={columns}
        pageSize={5}
        align="center"
        disableColumnFilter
        disableColumnSelector
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            buttonTitle,
          },
        }}
      />
    </div>
  );
}

export default DataTable;
