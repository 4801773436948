export const MockBills = [
    {
        id: 1,
        company_name: 'Renner-Kling',
        owner: {
            id: 1,
            email: "idrayton0@sciencedirect.com",
            name: "Inesita",
            lastName: "Drayton",
            username: "idrayton0",
            owner_phone: "2615842557",
            dni: "20546982",
            payment_status: true,
        },
        royalties: "2.83",
        address: "567 Kedzie Junction",
        location: "Marisgama",
        opening_time: '09:30:00',
        closing_time: '19:30:00',
        province: "Alabama",
        country: "Indonesia",
        phone: "4819700026",
        barbers: [
            {
                id: 1,
                full_name: 'Tommie Gettins',
                clients: 2,
                gain: 950,
                total_time: 1,
            },
            {
                id: 2,
                full_name: 'Gena Stacey',
                clients: 5,
                gain: 2500,
                total_time: 2.5,
            },
            {
                id: 3,
                full_name: 'Teodoro Bardey',
                clients: 8,
                gain: 3800,
                total_time: 4,
            }
        ],
        sellers: [
            {
                id: 1,
                full_name: "Idelle Jardein",
            },
            {
                id: 2,
                full_name: "Fiona Burtonshaw"
            },
            {
                id: 3,
                full_name: 'Emilee Packe'
            }
        ]
    },
    {
        id: 2,
        company_name: 'Grimes-Hessel',
        owner: {
            id: 2,
            email: "nlondesborough1@independent.co.uk",
            name: "Nickie",
            lastName: "Londesborough",
            username: "nlondesborough1",
            owner_phone: "2615842557",
            dni: "20546982",
            payment_status: false,
        },
        royalties: "6.44",
        address: "39657 Ridge Oak Plaza",
        location: "Citeureup",
        province: "Lisboa",
        country: "Indonesia",
        phone: "7663975763",
        opening_time: '08:30:00',
        closing_time: '18:30:00',
        barbers: [
            {
                id: 1,
                full_name: 'Lester Waddoups',
                clients: 2,
                gain: 950,
                total_time: 1,
            },
            {
                id: 2,
                full_name: 'Willy Coldbath',
                clients: 5,
                gain: 2500,
                total_time: 2.5,
            },
            {
                id: 3,
                full_name: 'Kaja Nuton',
                clients: 8,
                gain: 3800,
                total_time: 4,
            },
            {
                id: 4,
                full_name: 'Wyndham Jacobsson',
                clients: 4,
                gain: 1600,
                total_time: 2,
            }
        ],
        sellers: [
            {
                id: 1,
                full_name: 'Astrid Schiementz',
            },
            {
                id: 2,
                full_name: "Rahal Tesyro",
            }
        ]
    },
    {
        id: 3,
        company_name: 'Mante-Hand',
        owner: {
            id: 3,
            email: "tfauguel2@hhs.gov",
            name: "Tabina",
            lastName: "Fauguel",
            username: "tfauguel2",
            owner_phone: "2615842557",
            dni: "20546982",
            payment_status: true,
        },
        royalties: "9.81",
        address: "27 Heffernan Road",
        location: "Montauban",
        province: "Midi Pyrénées",
        country: "France",
        phone: "8328179342",
        opening_time: '08:30:00',
        closing_time: '18:30:00',
        barbers: [
            {
                id: 1,
                full_name: 'Bethany Sommerly',
                clients: 2,
                gain: 950,
                total_time: 1,
            },
            {
                id: 2,
                full_name: 'Vittorio Dinnies',
                clients: 4,
                gain: 1600,
                total_time: 2,
            },
            {
                id: 3,
                full_name: 'Eddy Reimer',
                clients: 5,
                gain: 2200,
                total_time: 2.5,
            },
            {
                id: 4,
                full_name: 'Corina McGerr',
                clients: 8,
                gain: 3800,
                total_time: 4,
            }
        ],
        sellers: [
            {
                id: 1,
                full_name: 'Noel Gozard',
            },
            {
                id: 2,
                full_name: "Amalie Yelland",
            }
        ]

    },
    {
        id: 4,
        company_name: 'Shanahan Inc',
        owner: {
            id: 4,
            email: "ajaukovic3@theglobeandmail.com",
            name: "Anny",
            lastName: "Jaukovic",
            username: "ajaukovic3",
            owner_phone: "2615842557",
            dni: "20546982",
            payment_status: true,
        },
        royalties: "4.23",
        address: "631 Jana Road",
        location: "Dahe",
        province: "Washington",
        country: "China",
        phone: "7084351897",
        opening_time: '08:30:00',
        closing_time: '18:30:00',
        barbers: [
            {
                id: 1,
                full_name: 'Thibaut Boost',
                clients: 2,
                gain: 950,
                total_time: 1,
            },
            {
                id: 2,
                full_name: 'Samaria Juckes',
                clients: 5,
                gain: 2500,
                total_time: 2.5,
            },
            {
                id: 3,
                full_name: 'Pauly Maginot',
                clients: 8,
                gain: 3800,
                total_time: 4,
            },
            {
                id: 4,
                full_name: 'Kev Klimek',
                clients: 4,
                gain: 2000,
                total_time: 2,
            }
        ],
        sellers: [
            {
                id: 1,
                full_name: 'Kennett Yoslowitz',
            },
            {
                id: 2,
                full_name: "Tripp Fawthorpe",
            }
        ]

    },
    {
        id: 5,
        company_name: 'Bosco and Sons',
        owner: {
            id: 5,
            email: "cstickney4@shutterfly.com",
            name: "Cristian",
            lastName: "Stickney",
            username: "cstickney4",
            owner_phone: "2615842557",
            dni: "20546982",
            payment_status: false,
        },
        royalties: "1.55",
        address: "23604 Mosinee Plaza",
        location: "Al Karmil",
        province: "Madrid",
        country: "Palestinian Territory",
        phone: "8272219150",
        opening_time: '08:30:00',
        closing_time: '18:30:00',
        barbers: [
            {
                id: 1,
                full_name: 'Elias Pashbee',
                clients: 2,
                gain: 980,
                total_time: 1,
            },
            {
                id: 2,
                full_name: 'Camila Derisly',
                clients: 4,
                gain: 2200,
                total_time: 2,
            },
            {
                id: 3,
                full_name: 'Sawyere Melarkey',
                clients: 1,
                gain: 550,
                total_time: 0.5,
            },
            {
                id: 4,
                full_name: 'Kippy Cottu',
                clients: 3,
                gain: 1700,
                total_time: 1.5,
            }
        ],
        sellers: [
            {
                id: 1,
                full_name: 'Marylee Benny',
            },
            {
                id: 2,
                full_name: "Clarence Dwerryhouse",
            }
        ]
    },
    {
        id: 6,
        company_name: 'Morar Group',
        owner: {
            id: 6,
            email: "jkopman5@baidu.com",
            name: "Jarret",
            lastName: "Kopman",
            username: "jkopman5",
            owner_phone: "2615842557",
            dni: "20546982",
            payment_status: true,
        },
        royalties: "0.26",
        address: "11 Muir Point",
        location: "Nagasari",
        province: "Lisboa",
        country: "Indonesia",
        phone: "6801247274",
        opening_time: '08:30:00',
        closing_time: '18:30:00',
        barbers: [
            {
                id: 1,
                full_name: 'Skye Langfat',
                clients: 4,
                gain: 2200,
                total_time: 2,
            },
            {
                id: 2,
                full_name: 'Dacia Kassidy',
                clients: 8,
                gain: 3800,
                total_time: 4,
            },
            {
                id: 3,
                full_name: 'Gav Duddan',
                clients: 4,
                gain: 2200,
                total_time: 2,
            },
            {
                id: 4,
                full_name: 'Winna Mahmood',
                clients: 2,
                gain: 950,
                total_time: 1,
            }
        ],
        sellers: [
            {
                id: 1,
                full_name: 'Parrnell Simoncini',
            },
            {
                id: 2,
                full_name: "Katya Kingscott",
            }
        ]
    },
    {
        id: 7,
        company_name: 'Bernhard, Schowalter and Ledner',
        owner: {
            id: 7,
            email: "lbegbie6@npr.org",
            name: "Leodora",
            lastName: "Begbie",
            username: "lbegbie6",
            owner_phone: "2615842557",
            dni: "20546982",
            payment_status: true,
        },
        royalties: "4.07",
        address: "26 Reinke Court",
        location: "Whittlesea",
        province: "Madrid",
        country: "South Africa",
        phone: "9896292828",
        opening_time: '08:30:00',
        closing_time: '18:30:00',
        barbers: [
            {
                id: 1,
                full_name: 'Kerri Gremain',
                clients: 3,
                gain: 1700,
                total_time: 1.5,
            },
            {
                id: 2,
                full_name: 'Gaelan Handforth',
                clients: 5,
                gain: 2500,
                total_time: 2.5,
            },
            {
                id: 3,
                full_name: 'Gan Stallen',
                clients: 7,
                gain: 2900,
                total_time: 3.5,
            },
            {
                id: 4,
                full_name: 'Gerome Blankley',
                clients: 8,
                gain: 3200,
                total_time: 4,
            }
        ],
        sellers: [
            {
                id: 1,
                full_name: 'Bjorn Smeuin',
            },
            {
                id: 2,
                full_name: "Evelin Sellan",
            }
        ]
    },
    {
        id: 8,
        company_name: 'Walsh Inc',
        owner: {
            id: 8,
            email: "fpascoe7@ft.com",
            name: "Fredi",
            lastName: "Pascoe",
            username: "fpascoe7",
            owner_phone: "2615842557",
            dni: "20546982",
            payment_status: true,
        },
        royalties: "8.64",
        address: "753 Independence Junction",
        location: "Manturovo",
        province: "Alabama",
        country: "Russia",
        phone: "3386110385",
        opening_time: '08:30:00',
        closing_time: '18:30:00',
        barbers: [
            {
                id: 1,
                full_name: 'Kris Orgel',
                clients: 2,
                gain: 1000,
                total_time: 1,
            },
            {
                id: 2,
                full_name: 'Armstrong Copyn',
                clients: 5,
                gain: 2500,
                total_time: 2.5,
            },
            {
                id: 3,
                full_name: 'Valentina Crosland',
                clients: 7,
                gain: 3200,
                total_time: 3.5,
            },
            {
                id: 4,
                full_name: 'Belvia Harborow',
                clients: 4,
                gain: 2000,
                total_time: 2,
            }
        ],
        sellers: [
            {
                id: 1,
                full_name: 'Skippy Kares',
            },
            {
                id: 2,
                full_name: "Fonsie Lerego",
            }
        ]
    },
    {
        id: 9,
        company_name: 'Pagac-Gaylord',
        owner: {
            id: 9,
            email: "gcovet8@deviantart.com",
            name: "Georg",
            lastName: "Covet",
            username: "gcovet8",
            owner_phone: "2615842557",
            dni: "20546982",
            payment_status: true,
        },
        royalties: "8.14",
        address: "34864 Bay Crossing",
        location: "Yangqing",
        province: "Alsace",
        country: "China",
        phone: "9923285780",
        opening_time: '08:30:00',
        closing_time: '18:30:00',
        barbers: [
            {
                id: 1,
                full_name: 'Perrine De Pietri',
                clients: 2,
                gain: 950,
                total_time: 1,
            },
            {
                id: 2,
                full_name: 'Gracia Fillgate',
                clients: 4,
                gain: 1600,
                total_time: 2,
            },
            {
                id: 3,
                full_name: 'Eddie Roose',
                clients: 6,
                gain: 2400,
                total_time: 3,
            },
            {
                id: 4,
                full_name: 'Bank Prester',
                clients: 8,
                gain: 3200,
                total_time: 4,
            }
        ],
        sellers: [
            {
                id: 1,
                full_name: 'Chery Chaplyn',
            },
            {
                id: 2,
                full_name: "Junia Twort",
            }
        ]
    },
    {
        id: 10,
        company_name: 'Legros-O-Reilly',
        owner: {
            id: 10,
            email: "seisikovitsh9@squarespace.com",
            name: "Stafani",
            lastName: "Eisikovitsh",
            username: "seisikovitsh9",
            owner_phone: "2615842557",
            dni: "20546982",
            payment_status: true,
        },
        royalties: "5.21",
        address: "58198 Morningstar Alley",
        location: "Guay",
        province: "Louisiana",
        country: "Indonesia",
        phone: "7317683084",
        opening_time: '08:30:00',
        closing_time: '18:30:00',
        barbers: [
            {
                id: 1,
                full_name: 'Perkin Kunes',
                clients: 8,
                gain: 3200,
                total_time: 4,
            },
            {
                id: 2,
                full_name: 'Benedick Wroath',
                clients: 4,
                gain: 2200,
                total_time: 2,
            },
            {
                id: 3,
                full_name: 'Mel Alenichev',
                clients: 2,
                gain: 980,
                total_time: 1,
            },
            {
                id: 4,
                full_name: 'Masha McManamon',
                clients: 4,
                gain: 2200,
                total_time: 2,
            }
        ],
        sellers: [
            {
                id: 1,
                full_name: 'Susy Baldung',
            },
            {
                id: 2,
                full_name: "Maureene Wanjek",
            }
        ]
    },

]

export const GetBillsMocks = () => {
    return new Promise((resolve, reject) => {
        /* setTimeout(() => { */
            return resolve(MockBills)
        /* }, 2000) */
    })
}
