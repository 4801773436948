import React, { useState, useEffect } from "react";
import "./styles.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams, useNavigate } from "react-router-dom";
import { getBarberShops } from "../../services/barbershops.services";
import { CustomButton } from "../../../../components/UI/CustomButton";
import { getBarberShopDetailPresenter } from "../../infrastructure/presentation/presenterProvider";

/* Una vez implementados los cambios en el endpoint, eliminar testState y reemplazaro por el estado de 
barberShop */

const BarberShopsDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [barberShop, setBarberShop] = useState({});
  const [barbers, setBarbers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [testState, setTestState] = useState({});

  const viewHandlers = {
    getBarberShopSuccess(data) {
      console.log("<<<<<<DATA DETAIL>>>>>>>", data.statusText);
      setTestState(data.data.data);
    },
    getBarberShopError(error) {
      console.log("<<<<<<ERROR>>>>>>>", error);
    },
  };

  const presenter = getBarberShopDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
  }, []);

  useEffect(() => {
    presenter.getBarberShopById(id);
    getBarberShops()
      .then((data) => {
        data.map((item) => {
          if (item.id === Number(id)) {
            setBarbers(item.barbers);
            setSellers(item.sellers);
            return setBarberShop(item);
          }
        });
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  }, [id]);

  const handleChange = (panel) => (evt, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    owner_id,
    name,
    phone,
    branch_offices_address_id,
    morning,
    afternoon,
  } = testState;

  return (
    <div className="barbershops-detail-page">
      {console.log("TestState: ", testState)}
      <div className={"bsDetail-screen-container"}>
        <div className={"bsDetail-title-container"}>
          <Typography variant="h2" gutterBottom component="div">
            {name}
          </Typography>
        </div>
      </div>
      <Grid
        container
        spacing={{ xs: 6, sm: 8, md: 2 }}
        className={"bsDetail-grid-container"}
      >
        <Grid item xs={12} md={4} className={"bsDetail-grid-item"}>
          <Card className={"bsDetail-data-container"} elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Dueño
              </Typography>
              <Typography variant="body1">{owner_id}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} className={"bsDetail-grid-item"}>
          <Card className={"bsDetail-data-container"} elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Teléfono
              </Typography>
              <Typography variant="body1">{phone}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} className={"bsDetail-grid-item"}>
          <Card className={"bsDetail-data-container"} elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Dirección
              </Typography>
              <Typography variant="body1">
                {branch_offices_address_id}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6} md={6} className={"bsDetail-grid-item"}>
          <Card className={"bsDetail-data-container"} elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Horarios Turno Mañana
              </Typography>
              <Typography variant="body1">{morning}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6} md={6} className={"bsDetail-grid-item"}>
          <Card className={"bsDetail-data-container"} elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Horarios Turno Tarde
              </Typography>
              <Typography variant="body1">{afternoon}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} className={"bsDetail-grid-item"}>
          <Card className={"bsDetail-data-container"} elevation={3}>
            <CardContent>
              <Accordion
                elevation={2}
                className="accordion-panels"
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  className="accordion-titles"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="barbershops-attributes">
                    <div className="attribute">Barberos</div>
                    <div
                      className="attribute-description"
                      style={{ color: "gray" }}
                    >
                      Lista de Barberos
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="accordion-data-container">
                    {barbers.map((item) => {
                      return (
                        <ul key={item.id} className="name-list-container">
                          • {item.full_name}
                        </ul>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div
        style={{ border: "2px solid black", width: "100%", marginTop: "3%" }}
      >
        Acá va un grid con los gráficos y el detalle de las métricas
      </div>
      <div className="bsDetail-back-button-container">
        <CustomButton
          onClick={() => navigate(-1)}
          variant="outlined"
          color="#FFC107"
          textColor={"black"}
        >
          Volver
        </CustomButton>
      </div>
    </div>
  );
};

export default BarberShopsDetail;
