//gateways
import { HttpGetAllBarbersGateway } from "./../gateways/HttpGetAllBarbersGateway";
import { HttpGetBarberGateway } from "./../gateways/HttpGetBarberGateway";
import { HttpUpdateBarberGateway } from './../gateways/HttpUpdateBarberGateway';
import { HttpCreateBarberGateway } from './../gateways/HttpCreateBarberGateway';
import { HttpDeleteBarberGateway } from './../gateways/HttpDeleteBarberGateway';

//presenters
import { BarbersHomePresenter } from "./BarbersHomePresenter";
import { BarbersDetailPresenter } from "./BarbersDetailPresenter";
import { BarbersEditPresenter } from "./BarbersEditPresenter";
import { BarberCreatePresenter } from './BarberCreatePresenter';
import { BarberDeletePresenter } from "./BarberDeletePresenter";

//trae todos los barberos
export const getBarbersHomePresenter = (viewHandlers) => {
  const getAllBarbersGateway = HttpGetAllBarbersGateway();

  return BarbersHomePresenter(getAllBarbersGateway, viewHandlers);
};

//trae barbero por id
export const getBarberDetailPresenter = (viewHandlers) => {
  const getBarberGateway = HttpGetBarberGateway();
  return BarbersDetailPresenter(getBarberGateway, viewHandlers);
};

// Actualiza un barbero existente
export const getBarbersEditPresenter = (viewHandlers) => {
  const getBarberGateway = HttpGetBarberGateway();
  const updateBarberGateway = HttpUpdateBarberGateway();
  return BarbersEditPresenter(
    getBarberGateway,
    updateBarberGateway,
    viewHandlers
  );
};

//Crea un barbero
export const getBarberCreatePresenter = (viewHandlers) => {
  const getCreateBarberGateway = HttpCreateBarberGateway();
  return BarberCreatePresenter(getCreateBarberGateway, viewHandlers);
};

// Elimina un barbero
export const getDeleteBarberPresenter = (viewHandlers) => {
  const getDeleteBarberGateway = HttpDeleteBarberGateway();
  return BarberDeletePresenter(getDeleteBarberGateway, viewHandlers);
} 

/* va uno por cada view - este es solo para traer a todos los barberos */
/* export const getBarbersPresenter = (viewHandlers) => {
  const getAllBarbers = HttpGetAllBarbersGateway();

  return BarbersPresenter(getAllBarbers, viewHandlers);
}; */

/* const getBarberGateway = HttpGetBarberGateway();
  const updateBarberGateway = HttpUpdateBarberGateway();
  return BarbersEditPresenter(getBarberGateway, updateBarberGateway, viewHandlers); */
