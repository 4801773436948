import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffc107",
    },
    neutral: {
      main: "#37474F",
    },
    success: {
      main: "#388E3C",
    },
    error: {
      main: "#D50000",
    },
  },
});

export default theme;
