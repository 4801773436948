export const OwnersDetailPresenter = (getOwnerGateway, viewHandlers) => {
  return {
    present() {
      console.log("OwnerDetail was rendered");
    },
    getOwner(id) {
      const getOwnerPromise = getOwnerGateway.getOwner(id);

      getOwnerPromise
        .then((data) => {
          viewHandlers.getOwnerSucces(data);
        })
        .catch((err) => {
          viewHandlers.getOwnerError(err);
        });
    },
  };
};
