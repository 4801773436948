import "./styles.scss";
import React, { useEffect } from "react";
import CustomForm from "../../components/Form/CustomForm";
import { Divider, Paper } from "@mui/material";
import { getBarberCreatePresenter } from "../../infrastructure/presentation/presenterProvider";

const BarberCreate = () => {
  const viewHandlers = {
    createBarberSucces(data) {
      console.log("<<<<<DATA>>>>>", data.statusText);
    },
    createBarberError(err) {
      console.log("<<<<<ERROR>>>>>", err);
    },
  };

  const presenter = getBarberCreatePresenter(viewHandlers);

  const createBarber = (data) => {
    presenter.createBarber(data);
  };

  useEffect(() => {
    presenter.present();
  }, []);

  return (
    <Paper elevation={8} className={"barbers-form-background"}>
      <div className={"create-barbers-title-container"}>
        <div className={"barbers-form-title"}>Nuevo barbero</div>
      </div>
      <Divider variant="middle" color={"black"} />
      <div className={"customForm-container"}>
        <CustomForm create={createBarber} />
      </div>
    </Paper>
  );
};

export default BarberCreate;
