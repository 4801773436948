export const RatesEditPresenter = (
  editRateGateway,
  getRateGateway,
  getAllBarberShopsGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("RatesEdit was rendered!");
    },
    getAllBarberShops() {
      const getAllBarberShopsPromise =
        getAllBarberShopsGateway.getAllBarberShops();

      getAllBarberShopsPromise
        .then((data) => {
          viewHandlers.getAllBarberShopsSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getAllBarberShopsError(err);
        });
    },
    getRateById(id) {
      const getRateByIdPromise = getRateGateway.getRate();

      getRateByIdPromise
        .then((data) => {
          viewHandlers.getRateByIdSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getRateByIdError(err);
        });
    },
    /* Función para ejecutar el update propiamente dicho */
    updateRate(data) {
      const updateRatePromise = editRateGateway.updateRate(data);

      updateRatePromise
        .then((data) => {
          viewHandlers.updateRateSuccess(data);
        })
        .catch((err) => {
          viewHandlers.updateRateError(err);
        });
    },
  };
};
