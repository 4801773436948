import React from "react";
import "./styles.scss";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Box,
} from "@mui/material";
import { Controller } from "react-hook-form";

const CustomTextField = ({
  name,
  control,
  label,
  rules,
  type,
  multiline,
  placeholder,
  endAdornment,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box className="customTextField-main-container">
          <FormControl
            className="customTextField-inner-container"
            fullWidth
            error={error !== undefined}
          >
            <InputLabel
              htmlFor="custom-textField"
              className={error ? "" : "customTextField-label"}
              sx={{ fontWeight: 500 }}
            >
              {label}
            </InputLabel>
            <Input
              type={type}
              multiline={multiline}
              id="custom-textField"
              aria-describedby="helper"
              className="customTextField-input"
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              endAdornment={endAdornment}
            ></Input>
            <FormHelperText className="customTextField-helperText" id="helper">
              {error ? error.message : null}
            </FormHelperText>
          </FormControl>
        </Box>
      )}
    />
  );
};

export default CustomTextField;
