export const BarberShopCreatePresenter = (
  createBarberShoGateway,
  getAllOwnersGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("BarberShopsCreate was rendered!");
    },
    getAllOwners() {
      const getAllOwnersPromise = getAllOwnersGateway.getAllOwners();

      getAllOwnersPromise
        .then((data) => {
          viewHandlers.getAllOwnersSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getAllOwnersError(err);
        });
    },
    createBarberShop(data) {
      const createBarberShopPromise =
        createBarberShoGateway.createBarberShop(data);

      createBarberShopPromise
        .then((res) => {
          viewHandlers.createBarberShopSuccess(res);
        })
        .catch((err) => {
          viewHandlers.createBarberShopError(err);
        });
    },
  };
};
