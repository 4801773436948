// Gateways
import { HttpGetAllOwnersGateway } from "./../gateways/HttpGetAllOwnersGateway";
import { HttpGetOwnerGateway } from "./../gateways/HttpGetOwnerGateway";
import { HttpDeleteOwnerGateway } from "./../gateways/HttpDeleteOwnerGateway";
import { HttpCreateOwnerGateway } from "./../gateways/HttpCreateOwnerGateway";
import { HttpUpdateOwnerGateway } from "./../gateways/HttpUpdateOwnerGateway";

// Presenters
import { OwnersHomePresenter } from "./OwnersHomePresenter";
import { OwnersDetailPresenter } from "./OwnersDetailPresenter";
import { OwnersDeletePresenter } from "./OwnersDeletePresenter";
import { OwnersCreatePresenter } from "./OwnersCreatePresenter";
import { OwnersEditPresenter } from "./OwnersEditPresenter";

// traer todos los dueños
export const getOwnersHomePresenter = (viewHandlers) => {
  const getAllOwnersGateway = HttpGetAllOwnersGateway();

  return OwnersHomePresenter(getAllOwnersGateway, viewHandlers);
};

// traer dueño por id
export const getOwnersDetailPresenter = (viewHandlers) => {
  const getOwnerGateway = HttpGetOwnerGateway();

  return OwnersDetailPresenter(getOwnerGateway, viewHandlers);
};

// eliminar un dueño
export const getOwnersDeletePresenter = (viewHandlers) => {
  const getDeleteOwnerGateway = HttpDeleteOwnerGateway();

  return OwnersDeletePresenter(getDeleteOwnerGateway, viewHandlers);
};

// crear un dueño
export const getOwnersCreatePresenter = (viewHandlers) => {
  const getCreateOwnerGateway = HttpCreateOwnerGateway();

  return OwnersCreatePresenter(getCreateOwnerGateway, viewHandlers);
};

// Actualizar un Dueño existente
export const getOwnersEditPresenter = (viewHandlers) => {
  const getOwnerGateway = HttpGetOwnerGateway();
  const updateOwnerGateway = HttpUpdateOwnerGateway();
  return OwnersEditPresenter(getOwnerGateway, 
  updateOwnerGateway,
  viewHandlers
  );
};
