export const FilteredRatesPresenter = (getAllRatesGateway, viewHandlers) => {
  return {
    present() {
      console.log("FilteredRates was rendered!");
    },

    getAllRates() {
      const getAllRatesPromise = getAllRatesGateway.getAllRates();

      getAllRatesPromise
        .then((data) => {
          viewHandlers.getAllRatesSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getAllRatesError(err);
        });
    },
  };
};
