export const MockBarberShops = [
  {
    id: 1,
    name: "Renner-Kling",
    owner: "idrayton0",
    royalties: "2.83",
    address: "567 Kedzie Junction",
    locality: "Marisgama",
    opening_time: "09:30:00",
    closing_time: "19:30:00",
    province: "Alabama",
    country: "Indonesia",
    phone: "4819700026",
    creation_date: "30/10/2022",
    barbers: [
      {
        id: 1,
        full_name: "Tommie Gettins",
      },
      {
        id: 2,
        full_name: "Gena Stacey",
      },
      {
        id: 3,
        full_name: "Teodoro Bardey",
      },
    ],
    sellers: [
      {
        id: 1,
        full_name: "Idelle Jardein",
      },
      {
        id: 2,
        full_name: "Fiona Burtonshaw",
      },
      {
        id: 3,
        full_name: "Emilee Packe",
      },
    ],
  },
  {
    id: 2,
    name: "Grimes-Hessel",
    owner: "nlondesborough1",
    royalties: "6.44",
    address: "39657 Ridge Oak Plaza",
    locality: "Citeureup",
    province: "Lisboa",
    country: "Indonesia",
    phone: "7663975763",
    creation_date: "05/01/2022",
    opening_time: "08:30:00",
    closing_time: "18:30:00",
    barbers: [
      {
        id: 1,
        full_name: "Lester Waddoups",
      },
      {
        id: 2,
        full_name: "Willy Coldbath",
      },
      {
        id: 3,
        full_name: "Kaja Nuton",
      },
      {
        id: 4,
        full_name: "Wyndham Jacobsson",
      },
    ],
    sellers: [
      {
        id: 1,
        full_name: "Astrid Schiementz",
      },
      {
        id: 2,
        full_name: "Rahal Tesyro",
      },
    ],
  },
  {
    id: 3,
    name: "Mante-Hand",
    owner: "tfauguel2",
    royalties: "9.81",
    address: "27 Heffernan Road",
    locality: "Montauban",
    province: "Midi Pyrénées",
    country: "France",
    phone: "8328179342",
    creation_date: "10/02/2022",
    opening_time: "08:30:00",
    closing_time: "18:30:00",
    barbers: [
      {
        id: 1,
        full_name: "Bethany Sommerly",
      },
      {
        id: 2,
        full_name: "Vittorio Dinnies",
      },
      {
        id: 3,
        full_name: "Eddy Reimer",
      },
      {
        id: 4,
        full_name: "Corina McGerr",
      },
    ],
    sellers: [
      {
        id: 1,
        full_name: "Noel Gozard",
      },
      {
        id: 2,
        full_name: "Amalie Yelland",
      },
    ],
  },
  {
    id: 4,
    name: "Shanahan Inc",
    owner: "ajaukovic3",
    royalties: "4.23",
    address: "631 Jana Road",
    locality: "Dahe",
    province: "Washington",
    country: "China",
    phone: "7084351897",
    creation_date: "16/06/2022",
    opening_time: "08:30:00",
    closing_time: "18:30:00",
    barbers: [
      {
        id: 1,
        full_name: "Thibaut Boost",
      },
      {
        id: 2,
        full_name: "Samaria Juckes",
      },
      {
        id: 3,
        full_name: "Pauly Maginot",
      },
      {
        id: 4,
        full_name: "Kev Klimek",
      },
    ],
    sellers: [
      {
        id: 1,
        full_name: "Kennett Yoslowitz",
      },
      {
        id: 2,
        full_name: "Tripp Fawthorpe",
      },
    ],
  },
  {
    id: 5,
    name: "Bosco and Sons",
    owner: "cstickney4",
    royalties: "1.55",
    address: "23604 Mosinee Plaza",
    locality: "Al Karmil",
    province: "Madrid",
    country: "Palestinian Territory",
    phone: "8272219150",
    creation_date: "10/12/2021",
    opening_time: "08:30:00",
    closing_time: "18:30:00",
    barbers: [
      {
        id: 1,
        full_name: "Elias Pashbee",
      },
      {
        id: 2,
        full_name: "Camila Derisly",
      },
      {
        id: 3,
        full_name: "Sawyere Melarkey",
      },
      {
        id: 4,
        full_name: "Kippy Cottu",
      },
    ],
    sellers: [
      {
        id: 1,
        full_name: "Marylee Benny",
      },
      {
        id: 2,
        full_name: "Clarence Dwerryhouse",
      },
    ],
  },
  {
    id: 6,
    name: "Morar Group",
    owner: "jkopman5",
    royalties: "0.26",
    address: "11 Muir Point",
    locality: "Nagasari",
    province: "Lisboa",
    country: "Indonesia",
    phone: "6801247274",
    creation_date: "17/08/2022",
    opening_time: "08:30:00",
    closing_time: "18:30:00",
    barbers: [
      {
        id: 1,
        full_name: "Skye Langfat",
      },
      {
        id: 2,
        full_name: "Dacia Kassidy",
      },
      {
        id: 3,
        full_name: "Gav Duddan",
      },
      {
        id: 4,
        full_name: "Winna Mahmood",
      },
    ],
    sellers: [
      {
        id: 1,
        full_name: "Parrnell Simoncini",
      },
      {
        id: 2,
        full_name: "Katya Kingscott",
      },
    ],
  },
  {
    id: 7,
    name: "Bernhard, Schowalter and Ledner",
    owner: "lbegbie6",
    royalties: "4.07",
    address: "26 Reinke Court",
    locality: "Whittlesea",
    province: "Madrid",
    country: "South Africa",
    phone: "9896292828",
    creation_date: "30/01/2022",
    opening_time: "08:30:00",
    closing_time: "18:30:00",
    barbers: [
      {
        id: 1,
        full_name: "Kerri Gremain",
      },
      {
        id: 2,
        full_name: "Gaelan Handforth",
      },
      {
        id: 3,
        full_name: "Gan Stallen",
      },
      {
        id: 4,
        full_name: "Gerome Blankley",
      },
    ],
    sellers: [
      {
        id: 1,
        full_name: "Bjorn Smeuin",
      },
      {
        id: 2,
        full_name: "Evelin Sellan",
      },
    ],
  },
  {
    id: 8,
    name: "Walsh Inc",
    owner: "fpascoe7",
    royalties: "8.64",
    address: "753 Independence Junction",
    locality: "Manturovo",
    province: "Alabama",
    country: "Russia",
    phone: "3386110385",
    creation_date: "30/04/2022",
    opening_time: "08:30:00",
    closing_time: "18:30:00",
    barbers: [
      {
        id: 1,
        full_name: "Kris Orgel",
      },
      {
        id: 2,
        full_name: "Armstrong Copyn",
      },
      {
        id: 3,
        full_name: "Valentina Crosland",
      },
      {
        id: 4,
        full_name: "Belvia Harborow",
      },
    ],
    sellers: [
      {
        id: 1,
        full_name: "Skippy Kares",
      },
      {
        id: 2,
        full_name: "Fonsie Lerego",
      },
    ],
  },
  {
    id: 9,
    name: "Pagac-Gaylord",
    owner: "gcovet8",
    royalties: "8.14",
    address: "34864 Bay Crossing",
    locality: "Yangqing",
    province: "Alsace",
    country: "China",
    phone: "9923285780",
    creation_date: "09/06/2022",
    opening_time: "08:30:00",
    closing_time: "18:30:00",
    barbers: [
      {
        id: 1,
        full_name: "Perrine De Pietri",
      },
      {
        id: 2,
        full_name: "Gracia Fillgate",
      },
      {
        id: 3,
        full_name: "Eddie Roose",
      },
      {
        id: 4,
        full_name: "Bank Prester",
      },
    ],
    sellers: [
      {
        id: 1,
        full_name: "Chery Chaplyn",
      },
      {
        id: 2,
        full_name: "Junia Twort",
      },
    ],
  },
  {
    id: 10,
    name: "Legros-O-Reilly",
    owner: "seisikovitsh9",
    royalties: "5.21",
    address: "58198 Morningstar Alley",
    locality: "Guay",
    province: "Louisiana",
    country: "Indonesia",
    phone: "7317683084",
    creation_date: "04/06/2022",
    opening_time: "08:30:00",
    closing_time: "18:30:00",
    barbers: [
      {
        id: 1,
        full_name: "Perkin Kunes",
      },
      {
        id: 2,
        full_name: "Benedick Wroath",
      },
      {
        id: 3,
        full_name: "Mel Alenichev",
      },
      {
        id: 4,
        full_name: "Masha McManamon",
      },
    ],
    sellers: [
      {
        id: 1,
        full_name: "Susy Baldung",
      },
      {
        id: 2,
        full_name: "Maureene Wanjek",
      },
    ],
  },
];

export const getBarberShops = () => {
  return new Promise((resolve, reject) => {
    /* setTimeout(() => { */
    return resolve(MockBarberShops);
    /* }, 2000) */
  });
};

export const GetBarberShopById = (id) => {
  return new Promise((resolve, reject) => {
    return resolve(
      MockBarberShops.filter((barberShop) => barberShop.id === id)
    );
  });
};
