export const OwnersDeletePresenter = (deleteOwnerGateway, viewHandlers) => {
  return {
    present() {
      console.log("OwnerDelete was rendered");
    },
    deleteOwner(id) {
      const deleteOwnerPromise = deleteOwnerGateway.deleteOwner(id);

      deleteOwnerPromise
        .then((data) => {
          viewHandlers.deleteOwnerSucces(data);
        })
        .catch((err) => {
          viewHandlers.deleteOwnerError(err);
        });
    },
  };
};
