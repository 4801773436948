//gateways
import { HttpGetAllSellersGateway } from "./../gateways/HttpGetAllSellersGateway";
import { HttpGetSellerGateway } from "./../gateways/HttpGetSellerGateway";
import { HttpCreateSellerGateway } from "./../gateways/HttpCreateSellerGateway";
import { HttpUpdateSellerGateway } from "./../gateways/HttpUpdateSellerGateway";
import { HttpDeleteSellerGateway } from './../gateways/HttpDeleteSellerGateway';

//presenters
import { SellersHomePresenter } from "./SellersHomePresenter";
import { SellersDetailPresenter } from "./SellersDetailPresenter";
import { SellersCreatePresenter } from "./SellersCreatePresenter";
import { SellersEditPresenter } from "./SellersEditPresenter";
import { SellersDeletePresenter } from './SellersDeletePresenter';

//traer todos los vendedores
export const getSellersHomePresenter = (viewHandlers) => {
  const getAllSellersGateway = HttpGetAllSellersGateway();

  return SellersHomePresenter(getAllSellersGateway, viewHandlers);
};

//traer vendedor por id
export const getSellersDetailPresenter = (viewHandlers) => {
  const getSellerGateway = HttpGetSellerGateway();

  return SellersDetailPresenter(getSellerGateway, viewHandlers);
};

//crear vendedor
export const getSellersCreatePresenter = (viewHandlers) => {
  const getCreateSellerGateway = HttpCreateSellerGateway();

  return SellersCreatePresenter(getCreateSellerGateway, viewHandlers);
};

//actualizar un vendedor
export const getSellersEditPresenter = (viewHandlers) => {
  const getSellerGateway = HttpGetSellerGateway();
  const updateSellerGateway = HttpUpdateSellerGateway();
  return SellersEditPresenter(
    getSellerGateway,
    updateSellerGateway,
    viewHandlers
  );
};

//eliminar un vendedor
export const getSellersDeletePresenter = (viewHandlers) => {
  const getDeleteSellerGateway = HttpDeleteSellerGateway();

  return SellersDeletePresenter(getDeleteSellerGateway, viewHandlers);
}
