import axios from "axios";

export const HttpRegisterGateway = () => {
  return {
    registerUser: async (data) => {
      const { name, lastname, documentNumber, email, password } = data;
      const body = {
        name,
        lastname,
        documentNumber,
        email,
        password,
      };
      return axios.post(`http://45.90.223.78/test/onbording/register`, body);
    },
  };
};
