import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box } from "@mui/system";
import { Controller } from "react-hook-form";

const CustomDatePicker = ({
  defaultValue,
  name,
  control,
  label,
  value,
  rules,
  disabled,
  clearErrors,
}) => {
  const [edit, setEdit] = useState(true);
  dayjs.extend(customParseFormat);

  useEffect(() => {
    if (value !== undefined) {
      setEdit(false);
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          rules={rules}
          render={({ field, onBlur, fieldState }) => (
            <DesktopDatePicker
              {...field}
              disabled={disabled}
              label={label}
              inputFormat="DD/MM/YYYY"
              value={
                value !== undefined && edit
                  ? dayjs(value, "DD/MM/YYYY")
                  : field.value
              }
              onChange={(newValue) => {
                field.onChange(newValue);
              }}
              onAccept={() => {
                clearErrors(name);
              }}
              renderInput={(inputs) => (
                <TextField
                  {...inputs}
                  onBlur={onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error && fieldState.error?.message}
                />
              )}
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
