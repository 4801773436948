export const RatesCreatePresenter = (
  getAllBarberShopsGateway,
  createRateGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("RatesCreate was rendered!");
    },
    getAllBarberShops() {
      const getAllBarberShopsPromise =
        getAllBarberShopsGateway.getAllBarberShops();

      getAllBarberShopsPromise
        .then((data) => {
          viewHandlers.getAllBarberShopsSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getAllBarberShopsError(err);
        });
    },
    createRate(data) {
      const createRatePromise = createRateGateway.createRate(data);

      createRatePromise
        .then((data) => {
          viewHandlers.createRateSuccess(data);
        })
        .catch((err) => {
          viewHandlers.createRateError(err);
        });
    },
  };
};
