/* Gateway */
import { HttpRegisterGateway } from "../gateways/HttpRegisterGateway";

/* Presenters */
import { RegisterPresenter } from "./RegisterPresenter";

export const getRegisterPresenter = (viewHandlers) => {
  const getRegisterGateway = HttpRegisterGateway();
  return RegisterPresenter(getRegisterGateway, viewHandlers);
};
