import { Grid, IconButton, InputAdornment, Stack } from "@mui/material";
import "./styles.scss";
import React, { useState, useEffect } from "react";
/* import CustomDatePicker from "../../../components/UI/DatePicker/CustomDatePicker"; */
import CustomTextField from "./../../../components/UI/CustomTextInput/index";
import { CustomButton } from "./../../../components/UI/CustomButton/index";
import { useForm } from "react-hook-form";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

const CustomForm = ({ id, sellerObject, create, update }) => {
  const { handleSubmit, control, setValue, reset } = useForm();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    sellerObject === undefined
      ? create(data)
      : update({ ...data, id: Number(id) });
    navigate("/sellers/all");
  };

  const resetForm = () => {
    reset({});
  };

  useEffect(() => {
    console.log("OWNER OBJECT <<<<<<<<<<<<<", sellerObject);
    if (sellerObject !== undefined) {
      setValue("name", sellerObject.name);
      setValue("lastname", sellerObject.lastname);
      setValue("email", sellerObject.email);
      setValue("password", sellerObject.password);
      setValue("documentNumber", sellerObject.documentNumber);
      setValue("username", sellerObject.username);
    }
  }, [setValue, sellerObject]);

  return (
    <div className="sellersForm-main-container">
      <Box className="sellersForm-grid-container">
        <Grid container spacing={2} className={"sellersForm-grid"}>
          {/* <Grid item xs={12} md={6} className={"grid-item-xs"}>
            <div className={"date-container"}>
              {console.log(seller.creation_date)}
              <CustomDatePicker
                name="creation_date"
                control={control}
                label="Fecha de Registro"
                value={seller !== undefined && seller.creation_date}
              />
            </div>
          </Grid> */}
          <div className="sellersForm-inputs-container">
            <Grid item xs={12} md={12} className="sellersForm-grid-item">
              <CustomTextField
                name="name"
                control={control}
                label="Nombre"
                value={sellerObject !== undefined ? sellerObject.name : ""}
                type={"text"}
                placeholder={"Lorem ipsum"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="sellersForm-inputs-container">
            <Grid item xs={12} md={12} className="sellersForm-grid-item">
              <CustomTextField
                name="lastname"
                control={control}
                label="Apellido"
                value={sellerObject !== undefined ? sellerObject.lastname : ""}
                type={"text"}
                placeholder={"Lorem ipsum"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="sellersForm-inputs-container">
            <Grid item xs={12} md={12} className="sellersForm-grid-item">
              <CustomTextField
                name="documentNumber"
                control={control}
                label="DNI"
                value={
                  sellerObject !== undefined ? sellerObject.documentNumber : ""
                }
                type={"text"}
                placeholder={"DNI sin puntos ni comas"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="sellersForm-inputs-container">
            <Grid item xs={12} md={12} className="sellersForm-grid-item">
              <CustomTextField
                name="email"
                control={control}
                label="Email"
                value={sellerObject !== undefined ? sellerObject.email : ""}
                type={"email"}
                placeholder={"mail@mail.com"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
                    message: "El formato ingresado no es válido",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="sellersForm-inputs-container">
            <Grid item xs={12} md={12} className="sellersForm-grid-item">
              <CustomTextField
                name="password"
                control={control}
                label="Contraseña"
                value={sellerObject !== undefined ? sellerObject.password : ""}
                type={showPassword ? "text" : "password"}
                placeholder={"*********"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDown}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                rules={{
                  required: {
                    value: false,
                    message: "Campo no obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          {sellerObject && (
            <div className="sellersForm-inputs-container">
              <Grid item xs={12} md={12} className="sellersForm-grid-item">
                <CustomTextField
                  name="username"
                  control={control}
                  label="Nombre de Usuario"
                  value={
                    sellerObject !== undefined ? sellerObject.username : ""
                  }
                  type={"text"}
                  placeholder={"Lorem ipsum"}
                  rules={{
                    required: {
                      value: true,
                      message: "Campo obligatorio",
                    },
                  }}
                />
              </Grid>
            </div>
          )}

          <Grid
            item
            xs={12}
            md={12}
            mb={3}
            className={"sellersForm-grid-buttons-container"}
          >
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "row",
              }}
              spacing={3}
              className="sellersForm-buttons-stack"
            >
              <CustomButton
                onClick={handleSubmit(onSubmit)}
                variant={"text"}
                textColor={"black"}
              >
                Submit
              </CustomButton>
              <CustomButton
                onClick={resetForm}
                variant={"text"}
                textColor={"black"}
              >
                Reset
              </CustomButton>
            </Stack>
            <div className="sellersForm-back-button-container">
              <CustomButton
                onClick={() => navigate(-1)}
                variant="outlined"
                color="#FFC107"
                textColor={"black"}
              >
                Volver
              </CustomButton>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CustomForm;
