export const BarberCreatePresenter = (createBarberGateway, viewHandlers) => {
  return {
    present() {
      console.log("BarberCreate was rendered!");
    },
    createBarber(data) {
      const createBarberPromise = createBarberGateway.createBarber(data);

      createBarberPromise
        .then((res) => {
          viewHandlers.createBarberSucces(res);
        })
        .catch((err) => {
          viewHandlers.createBarberError(err);
        });
    },
  };
};
