import React, { createContext, useState } from "react";
import jwt_decode from "jwt-decode";

const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [jwt, setJwt] = useState(() => window.sessionStorage.getItem("jwt"));
  const [user, setUser] = useState(() =>
    window.sessionStorage.getItem("userData")
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [decode, setDecode] = useState({});

  const login = (res) => {
    setLoading(true);
    try {
      window.sessionStorage.setItem("jwt", res.token);
      window.sessionStorage.setItem("userData", JSON.stringify(res.data));
      setError(false);
      setLoading(false);
      setJwt(res.token);
      setDecode(jwt_decode(res.token));
      setUser(res.data);
    } catch (error) {
      window.sessionStorage.removeItem("jwt");
      window.sessionStorage.removeItem("userData");
      setError(true);
      setLoading(false);
      console.log("CONTEXT ERROR: ", error);
    }
  };

  const verifySessionStatus = () => {
    if (jwt) {
      const now = new Date();
      const exp = new Date(decode.exp * 1000);
      if (now >= exp) {
        window.sessionStorage.removeItem("jwt");
        window.sessionStorage.removeItem("userData");
        setDecode(null);
        setJwt(null);
        setUser(null);
        return false;
      } else {
        return true;
      }
    }
  };

  const logout = () => {
    window.sessionStorage.removeItem("jwt");
    window.sessionStorage.removeItem("userData");
    setLoading(false);
    setDecode(null);
    setJwt(null);
    setUser(null);
  };

  const data = {
    jwt,
    setJwt,
    user,
    setUser,
    isLogged: Boolean(jwt),
    hasLoginError: error,
    setLoginError: setError,
    login,
    logout,
    verifySessionStatus,
    isLoading: loading,
  };

  return <UserContext.Provider value={data}> {children} </UserContext.Provider>;
};

export { UserContextProvider };

export default UserContext;
