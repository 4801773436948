import { Routes, Route } from "react-router-dom";
import "./styles/App.scss";
import Register from "./modules/Register/views";
import BarbersLogin from "./modules/Login/views/BarbersLogin/index";
import theme from "./ThemeConfig";
import { ThemeProvider } from "@mui/material";
import Home from "./modules/Dashboard/views";
import MiniDrawer from "../src/modules/header/views/index";
import ErrorScreen from "./components/ErrorScreen";

import { UserContextProvider } from "./context/index.jsx";

import * as webAppRoutes from "./constants/webAppRoutes";
import PrivateRoute from "./utils/navigation";
import LoginSelector from "./modules/Login/views/LoginSelector";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <UserContextProvider>
        <MiniDrawer>
          <Routes>
            <Route path="/" index element={<LoginSelector />} />
            <Route path="/login/barber" index element={<BarbersLogin />} />
            <Route path="/singin" element={<Register />} />
            <Route path="/home" element={<PrivateRoute Component={Home} />} />
            <Route path="/*" element={<ErrorScreen />} />
            <Route
              path={webAppRoutes.OWNERS_ALL.path}
              element={webAppRoutes.OWNERS_ALL.element}
            />
            <Route
              path={webAppRoutes.OWNERS_ADD.path}
              element={webAppRoutes.OWNERS_ADD.element}
            />
            <Route
              path={webAppRoutes.OWNERS_DETAIL.path}
              element={webAppRoutes.OWNERS_DETAIL.element}
            />
            <Route
              path={webAppRoutes.OWNER_EDIT.path}
              element={webAppRoutes.OWNER_EDIT.element}
            />
            <Route
              path={webAppRoutes.OWNER_DELETE.path}
              element={webAppRoutes.OWNER_DELETE.element}
            />
            <Route
              path={webAppRoutes.BARBERS_ALL.path}
              element={webAppRoutes.BARBERS_ALL.element}
            />
            <Route
              path={webAppRoutes.BARBERS_ADD.path}
              element={webAppRoutes.BARBERS_ADD.element}
            />
            <Route
              path={webAppRoutes.BARBERS_DETAIL.path}
              element={webAppRoutes.BARBERS_DETAIL.element}
            />
            <Route
              path={webAppRoutes.BARBERS_EDIT.path}
              element={webAppRoutes.BARBERS_EDIT.element}
            />
            <Route
              path={webAppRoutes.BARBERS_DELETE.path}
              element={webAppRoutes.BARBERS_DELETE.element}
            />
            <Route
              path={webAppRoutes.BARBERSHOPS_ALL.path}
              element={webAppRoutes.BARBERSHOPS_ALL.element}
            />
            <Route
              path={webAppRoutes.BARBERSHOPS_ADD.path}
              element={webAppRoutes.BARBERSHOPS_ADD.element}
            />
            <Route
              path={webAppRoutes.BARBERSHOPS_DETAIL.path}
              element={webAppRoutes.BARBERSHOPS_DETAIL.element}
            />
            <Route
              path={webAppRoutes.BARBERSHOPS_EDIT.path}
              element={webAppRoutes.BARBERSHOPS_EDIT.element}
            />
            <Route
              path={webAppRoutes.BARBERSHOPS_DELETE.path}
              element={webAppRoutes.BARBERSHOPS_DELETE.element}
            />
            <Route
              path={webAppRoutes.RATES_ALL.path}
              element={webAppRoutes.RATES_ALL.element}
            />
            <Route
              path={webAppRoutes.RATES_FILTERED.path}
              element={webAppRoutes.RATES_FILTERED.element}
            />
            <Route
              path={webAppRoutes.RATES_ADD.path}
              element={webAppRoutes.RATES_ADD.element}
            />
            <Route
              path={webAppRoutes.RATES_DETAIL.path}
              element={webAppRoutes.RATES_DETAIL.element}
            />
            <Route
              path={webAppRoutes.RATES_EDIT.path}
              element={webAppRoutes.RATES_EDIT.element}
            />
            <Route
              path={webAppRoutes.RATES_DELETE.path}
              element={webAppRoutes.RATES_DELETE.element}
            />
            <Route
              path={webAppRoutes.SELLERS_ALL.path}
              element={webAppRoutes.SELLERS_ALL.element}
            />
            <Route
              path={webAppRoutes.SELLERS_ADD.path}
              element={webAppRoutes.SELLERS_ADD.element}
            />
            <Route
              path={webAppRoutes.SELLERS_DETAIL.path}
              element={webAppRoutes.SELLERS_DETAIL.element}
            />
            <Route
              path={webAppRoutes.SELLERS_EDIT.path}
              element={webAppRoutes.SELLERS_EDIT.element}
            />
            <Route
              path={webAppRoutes.SELLERS_DELETE.path}
              element={webAppRoutes.SELLERS_DELETE.element}
            />
            <Route
              path={webAppRoutes.BILLS_HOME.path}
              element={webAppRoutes.BILLS_HOME.element}
            />
            <Route
              path={webAppRoutes.CUSTOMERS_HOME.path}
              element={webAppRoutes.CUSTOMERS_HOME.element}
            />
          </Routes>
        </MiniDrawer>
      </UserContextProvider>
    </ThemeProvider>
  );
}

export default App;
