export const SellersEditPresenter = (
  getSellerGateway,
  updateSellerGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("SellersEdit was rendered");
    },
    getSeller(id) {
      const getSellerPromise = getSellerGateway.getSeller(id);

      getSellerPromise
        .then((data) => {
          viewHandlers.getSellerSucces(data);
        })
        .catch((err) => {
          viewHandlers.getSellerError(err);
        });
    },
    updateSeller(data) {
      const updateSellerPromise = updateSellerGateway.updateSeller(data);

      updateSellerPromise
        .then((data) => {
          viewHandlers.updateSellerSucces(data);
        })
        .catch((err) => {
          viewHandlers.updateSellerError(err);
        });
    },
  };
};
