import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useParams, useNavigate } from "react-router-dom";
import { CustomButton } from "../../../../components/UI/CustomButton";
import { GetRateById } from "../../services/rates.services";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { getRatesDetailPresenter } from "../../infrastructure/presentation/presenterProvider";

const RatesDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [rate, setRate] = useState({});

  const viewHandlers = {
    getRateSuccess(data) {
      console.log("<<<<<<DETAIL RATE >>>>>>>", data);
    },
    getRateError(err) {
      console.log("<<<<<<DETAIL RATE ERROR>>>>>>>", err);
    },
  };

  const presenter = getRatesDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    GetRateById(id)
      .then((data) => setRate(data[0]))
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  }, [id]);

  return (
    <div className="rates-detail-page">
      <div className="rates-detail-title-container">
        <div className="rates-detail-title">
          <Typography variant="h2" gutterBottom component="div">
            Detalle de tarifa
          </Typography>
        </div>
      </div>
      <Grid
        container
        rowSpacing={{ xs: 10, sm: 10, md: 2 }}
        columnSpacing={{ xs: 4, sm: 8, md: 12 }}
        className="rates-detail-grid-container"
      >
        <Grid item xs={12} md={6} className="rates-detail-grid-item">
          <Card className="rates-detail-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Día
              </Typography>
              <Typography variant="body1">"Lunes"</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} className="rates-detail-grid-item">
          <Card className="rates-detail-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Turno
              </Typography>
              <Typography variant="body1">"Mañana"</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} className="rates-detail-grid-item">
          <Card className="rates-detail-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Tipo de trabajo
              </Typography>
              <Typography variant="body1">"Corte + barba"</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3} className="rates-detail-grid-item">
          <Card className="rates-detail-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Bajada de bandera
              </Typography>
              <Typography variant="body1">"$1000"</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3} className="rates-detail-grid-item">
          <Card className="rates-detail-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Intervalo
              </Typography>
              <Typography variant="body1">"4 segundos"</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3} className="rates-detail-grid-item">
          <Card className="rates-detail-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Precio de intervalo
              </Typography>
              <Typography variant="body1">"$15"</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3} className="rates-detail-grid-item">
          <Card className="rates-detail-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Precio máximo
              </Typography>
              <Typography variant="body1">"$1500"</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div className="rates-detail-back-button-container">
        <CustomButton
          onClick={() => navigate(-1)}
          variant="outlined"
          color="#FFC107"
          textColor={"black"}
        >
          Volver
        </CustomButton>
      </div>
    </div>
  );
};

export default RatesDetail;
