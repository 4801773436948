import { Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./styles.scss";
import { CustomButton } from "../../../components/UI/CustomButton";
import CustomSelect from "../../../components/UI/CustomSelect";
import CustomDatePicker from "../../../components/UI/DatePicker/CustomDatePicker";
import CustomTextField from "../../../components/UI/CustomTextInput";
import { useNavigate } from "react-router-dom";

const BarberShopsForm = ({ id, create, barberShop, owners, update }) => {
  const { handleSubmit, control, setValue, reset } = useForm();
  console.log("BARBERSHOP: ", barberShop);
  const navigate = useNavigate();

  const onSubmit = (data) => {
    barberShop === undefined
      ? create(data)
      : update({ ...data, id: Number(id) });
    navigate("/barbershops/all");
  };

  const resetFormBarberShop = () => {
    reset({});
  };

  useEffect(() => {
    if (barberShop !== undefined) {
      setValue("owner_id", barberShop.owner);
      /* setValue("opening_date", barberShop.opening_date); */
      setValue("name", barberShop.name);
      /* setValue("royalties", barberShop.royalties); */
      setValue("address", barberShop.address);
      setValue("locality", barberShop.locality);
      setValue("province", barberShop.province);
      setValue("country", barberShop.country);
      setValue("phone", barberShop.phone);
    }
  }, [barberShop, setValue]);

  return (
    <div className="barbershops-main-container">
      <Box className="barbershops-grid-container">
        <Grid container spacing={2} className={"grid"}>
          <Grid container item spacing={2} className={"inner-grid"}>
            <Grid item xs={12} md={6} className={"grid-item-small"}>
              <div className="inner-grid-inputs-container">
                <CustomSelect
                  name="owner_id"
                  control={control}
                  label="Dueño"
                  options={owners}
                  rules={{
                    required: {
                      value: true,
                      message: "Campo obligatorio",
                    },
                  }}
                />
              </div>
            </Grid>

            {/* <Grid item xs={12} md={6} className={"grid-item-small"}>
              <div className="inner-grid-inputs-container">
                <div className="datePicker-container">
                  <CustomDatePicker
                    name="opening_date"
                    disabled={true}
                    control={control}
                    defaultValue={
                      barberShop === undefined ? null : barberShop.creation_date
                    }
                    label="Fecha de Apertura"
                    value={
                      barberShop !== undefined
                        ? barberShop.creation_date
                        : undefined
                    }
                  />
                </div>
              </div>
            </Grid> */}
          </Grid>
          <div className="text-inputs-main-container">
            <Grid item xs={12} md={12} className={"grid-item"}>
              <CustomTextField
                name="name"
                control={control}
                label="Nombre Barbería"
                value={barberShop !== undefined ? barberShop.name : ""}
                type={"text"}
                placeholder={"Barbershop"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo Obligatorio",
                  },
                }}
              />
            </Grid>
          </div>

          {/*  <Grid item xs={12} md={12} className={"grid-item"}>
              <CustomTextField
                name="royalties"
                control={control}
                label="Regalías"
                value={barberShop !== undefined ? barberShop.name : ""}
                numeric={true}
                placeholder={"$***"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo Obligatorio",
                  },
                }}
              />
            </Grid> */}
          <div className="text-inputs-main-container">
            <Grid item xs={12} md={12} className={"grid-item"}>
              <CustomTextField
                name="address"
                control={control}
                label="Dirección"
                value={barberShop !== undefined ? barberShop.address : ""}
                type={"text"}
                placeholder={"Calle falsa 123"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo Obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="text-inputs-main-container">
            <Grid item xs={12} md={12} className={"grid-item"}>
              <CustomTextField
                name="locality"
                control={control}
                label="Localidad"
                value={barberShop !== undefined ? barberShop.locality : ""}
                type={"text"}
                placeholder={"Ciudad"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="text-inputs-main-container">
            <Grid item xs={12} md={12} className={"grid-item"}>
              <CustomTextField
                name="province"
                control={control}
                label="Provincia"
                value={barberShop !== undefined ? barberShop.province : ""}
                type={"text"}
                placeholder={"Mendoza"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="text-inputs-main-container">
            <Grid item xs={12} md={12} className={"grid-item"}>
              <CustomTextField
                name="country"
                control={control}
                label="País"
                value={barberShop !== undefined ? barberShop.country : ""}
                type={"text"}
                placeholder="Argentina"
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="text-inputs-main-container">
            <Grid item xs={12} md={12} className={"grid-item"}>
              <CustomTextField
                name="phone"
                control={control}
                label="Teléfono"
                value={barberShop !== undefined ? barberShop.phone : ""}
                type={"number"}
                placeholder="2610123456"
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    pattern: /[\d+(,\d+)?]{10}$/i,
                    message: "El número ingresado no es válido",
                  },
                }}
              />
            </Grid>
          </div>
          <Grid
            item
            xs={12}
            md={12}
            mb={3}
            className={"grid-buttons-container"}
          >
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "row",
              }}
              spacing={3}
              className="buttons-stack"
            >
              <CustomButton
                onClick={handleSubmit(onSubmit)}
                variant={"text"}
                textColor={"black"}
              >
                Submit
              </CustomButton>
              <CustomButton
                onClick={resetFormBarberShop}
                variant={"text"}
                textColor={"black"}
              >
                Reset
              </CustomButton>
            </Stack>
            <div className="back-btn-container">
              <CustomButton
                onClick={() => navigate(-1)}
                variant="outlined"
                color="#FFC107"
                textColor={"black"}
              >
                Volver
              </CustomButton>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BarberShopsForm;
