import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Divider, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import RatesForm from "../../components/RatesForm/form";
import { getRatesEditPresenter } from "../../infrastructure/presentation/presenterProvider";

const RatesEdit = () => {
  const { id } = useParams();
  const [rate, setRate] = useState({});
  const [barberShops, setBarberShops] = useState([]);

  const viewHandlers = {
    getAllBarberShopsSuccess(data) {
      console.log("<<<<<<ALL BARBERSHOPS>>>>>>", data.statusText);
      let barberShopsList = data.data.data;
      setBarberShops(
        barberShopsList.map((item) => ({
          value: item.id,
          title: item.name,
        }))
      );
    },
    getAllBarberShopsError(err) {
      console.log("<<<<<<BARBERSHOPS ERROR>>>>>>>", err);
    },
    updateRateSuccess(data) {
      console.log("<<<<<<UPDATE RATE>>>>>>", data);
    },
    updateRateError(err) {
      console.log("<<<<<<UPDATE ERROR>>>>>>>", err);
    },
    /* Falta el de obtener por id y el de actualizar */
  };

  const presenter = getRatesEditPresenter(viewHandlers);

  const editRate = (data) => {
    /* presenter.updateRate(data); */
    console.log("DATA: ", data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getAllBarberShops();
  }, []);

  return (
    <div className="rates-edit-screen">
      <Paper elevation={8} className={"edit-form-container"}>
        <div className={"edit-form-title-container"}>
          <h5 className={"edit-form-title"}>Editar Tarifa</h5>
        </div>
        <Divider variant="middle" color={"black"} />
        <div className="edit-customForm-container">
          <RatesForm
            id={id}
            edit={editRate}
            rate={rate}
            barberShops={barberShops}
          />
        </div>
      </Paper>
    </div>
  );
};

export default RatesEdit;
