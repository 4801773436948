import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./styles.scss";
import { Typography, Grid, Card, CardContent } from "@mui/material";
import { CustomButton } from "../../../../components/UI/CustomButton";
import { useNavigate } from "react-router-dom";
import { getBarberDetailPresenter } from "../../infrastructure/presentation/presenterProvider";

const BarbersDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [barber, setBarber] = useState({});

  const viewHandlers = {
    getBarberSucces(data) {
      setBarber(data.data.data);
    },
    getBarberError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getBarberDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
  }, []);

  useEffect(() => {
    presenter.getBarber(id);
  }, [id]);

  return (
    <div className="barber-detail-main-screen">
      <div className="barbers-detail-screen-title-container">
        <div className="barbers-detail-screen-title">
          <Typography variant="h2" gutterBottom component="div">
            Detalle Barbero
          </Typography>
        </div>
      </div>
      <Grid container spacing={2} className="barbers-detail-grid-container">
        <Grid item xs={12} md={8} className="barbers-detail-grid-item">
          <Card className={"barbers-detail-data-container"} elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Nombre Completo
              </Typography>
              <Typography variant="body1">{`${barber.name} ${barber.lastname}`}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} className="barbers-detail-grid-item">
          <Card className={"barbers-detail-data-container"} elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                DNI
              </Typography>
              <Typography variant="body1">{barber.documentNumber}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} className="barbers-detail-grid-item">
          <Card className={"barbers-detail-data-container"} elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Nombre de usuario
              </Typography>
              <Typography variant="body1">{barber.username}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={8} className="barbers-detail-grid-item">
          <Card className={"barbers-detail-data-container"} elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Email
              </Typography>
              <Typography variant="body1">{barber.email}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} className="barbers-detail-grid-item">
          <Card className={"barbers-detail-data-container"} elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Barbería
              </Typography>
              <Typography variant="body1">{barber.company_name}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} className="barbers-detail-grid-item">
          <Card className={"barbers-detail-data-container"} elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Barbero desde
              </Typography>
              <Typography variant="body1">
                {new Date(barber.created_at).toLocaleDateString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div className="barbers-detail-back-btn-container">
        <CustomButton
          onClick={() => navigate(-1)}
          variant="outlined"
          color="#FFC107"
          textColor={"black"}
        >
          Volver
        </CustomButton>
      </div>
    </div>
  );
};

export default BarbersDetail;
