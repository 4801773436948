export const OwnersHomePresenter = (getAllOwnersGateway, viewHandlers) => {
  return {
    present() {
      console.log("OwnersHome was rendered");
    },
    getAllOwners() {
      const getAllOwnersPromise = getAllOwnersGateway.getAllOwners();

      getAllOwnersPromise
        .then((data) => {
          viewHandlers.getAllOwnersSucces(data);
        })
        .catch((err) => {
          viewHandlers.getAllOwnersError(err);
        });
    },
  };
};
