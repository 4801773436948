//hay un solo Provider por modulo o apartado (carpeta apartado)
//hay un Presenter por cada vista, es decir que si Barbers tiene 5 vistas, va a tener 5 presenters y 
// 1 provider en la carpeta PRESENTATION


//Por otra parte, vamos a tener 1 GATEWAY por cada endpoint, eso quiere decir que vamos a poder tener + de 1 
//endpoint por vista, por ejemplo:
//para la pantalla EDITAR, vamos a tener un GATEWAY que haga un axios.get para traer la DATA y rellenar los campos, 
// y por otro lado habrá otra GATEWAY para hacer la actualización, es decir el axios.put

export const BarbersHomePresenter = (getAllBarbersGateway, viewHandlers) => {
  return {
    present() {
      console.log("Barbers home was rendered");
    },
    getAllBarbers() {
      const getAllBarbersPromise = getAllBarbersGateway.getAllBarbers();

      getAllBarbersPromise
        .then((data) => {
          viewHandlers.getAllBarbersSucces(data);
        })
        .catch((err) => {
          viewHandlers.getAllBarbersError(err);
        });
    },
  };
};

