export const BarbersDetailPresenter = (getBarberGateway, viewHandlers) => {
  return {
    present() {
      console.log("Barber Detail was rendered");
    },
    getBarber(id) {
      const getBarberPromise = getBarberGateway.getBarber(id);

      getBarberPromise
        .then((data) => {
          viewHandlers.getBarberSucces(data);
        })
        .catch((error) => viewHandlers.getBarberError(error));
    },
  };
};
