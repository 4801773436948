import axios from "axios";

export const HttpUpdateBarberShopGateway = () => {
  return {
    updateBarberShop: async (data) => {
      const { id, owner_id, name, phone } = data;
      const body = { id, owner_id, name, phone };
      return axios.put(
        `http://45.90.223.78/test/business/branch-office/update`,
        body
      );
    },
  };
};