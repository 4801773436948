export const mockCustomers = [
  {
    id: 1,
    firstname: "Desmund",
    lastname: "Payle",
    dni: "02-168-5459",
    email: "dpayle0@va.gov",
    gender: "Other",
    phone: "251 934 8215",
    age: 23,
  },
  {
    id: 2,
    firstname: "Nedda",
    lastname: "Binley",
    dni: "50-828-0241",
    email: "nbinley1@auda.org.au",
    gender: "Other",
    phone: "642 461 1879",
    age: 25,
  },
  {
    id: 3,
    firstname: "Thadeus",
    lastname: "Blatchford",
    dni: "05-974-9972",
    email: "tblatchford2@blog.com",
    gender: "Male",
    phone: "235 386 2481",
    age: 52,
  },
  {
    id: 4,
    firstname: "Corene",
    lastname: "Dunk",
    dni: "83-534-6959",
    email: "cdunk3@a8.net",
    gender: "Other",
    phone: "742 617 0786",
    age: 58,
  },
  {
    id: 5,
    firstname: "Robbert",
    lastname: "Bownas",
    dni: "52-594-4360",
    email: "rbownas4@ucla.edu",
    gender: "Male",
    phone: "580 905 8266",
    age: 21,
  },
  {
    id: 6,
    firstname: "Hedwiga",
    lastname: "Kirlin",
    dni: "24-930-8243",
    email: "hkirlin5@google.es",
    gender: "Other",
    phone: "582 169 9739",
    age: 26,
  },
  {
    id: 7,
    firstname: "Lucias",
    lastname: "Grabb",
    dni: "45-464-0210",
    email: "lgrabb6@themeforest.net",
    gender: "Male",
    phone: "594 666 6609",
    age: 37,
  },
  {
    id: 8,
    firstname: "Deck",
    lastname: "Crickett",
    dni: "95-765-1985",
    email: "dcrickett7@seattletimes.com",
    gender: "Male",
    phone: "309 837 3103",
    age: 36,
  },
  {
    id: 9,
    firstname: "Hobey",
    lastname: "Monteaux",
    dni: "95-743-6657",
    email: "hmonteaux8@constantcontact.com",
    gender: "Male",
    phone: "239 629 0001",
    age: 30,
  },
  {
    id: 10,
    firstname: "Homer",
    lastname: "Turnbull",
    dni: "76-615-7180",
    email: "hturnbull9@forbes.com",
    gender: "Male",
    phone: "978 190 2439",
    age: 29,
  },
  {
    id: 11,
    firstname: "Leesa",
    lastname: "Lynagh",
    dni: "72-660-0482",
    email: "llynagha@dropbox.com",
    gender: "Female",
    phone: "972 403 6796",
    age: 35,
  },
  {
    id: 12,
    firstname: "Georgi",
    lastname: "Canet",
    dni: "27-894-5215",
    email: "gcanetb@mysql.com",
    gender: "Male",
    phone: "225 239 9911",
    age: 53,
  },
  {
    id: 13,
    firstname: "Benedikt",
    lastname: "Ambage",
    dni: "80-114-9914",
    email: "bambagec@pen.io",
    gender: "Male",
    phone: "598 796 4904",
    age: 19,
  },
  {
    id: 14,
    firstname: "Millie",
    lastname: "Powder",
    dni: "06-396-9102",
    email: "mpowderd@weebly.com",
    gender: "Male",
    phone: "558 428 0170",
    age: 43,
  },
  {
    id: 15,
    firstname: "Adrian",
    lastname: "Tomlin",
    dni: "70-926-0124",
    email: "atomline@elegantthemes.com",
    gender: "Male",
    phone: "719 913 1951",
    age: 48,
  },
  {
    id: 16,
    firstname: "Bradan",
    lastname: "Faux",
    dni: "95-813-1643",
    email: "bfauxf@telegraph.co.uk",
    gender: "Male",
    phone: "227 902 3709",
    age: 57,
  },
  {
    id: 17,
    firstname: "Maiga",
    lastname: "Munt",
    dni: "71-800-0015",
    email: "mmuntg@dyndns.org",
    gender: "Female",
    phone: "969 545 2263",
    age: 16,
  },
  {
    id: 18,
    firstname: "Timmie",
    lastname: "Pele",
    dni: "69-601-3160",
    email: "tpeleh@ed.gov",
    gender: "Male",
    phone: "436 680 0524",
    age: 17,
  },
  {
    id: 19,
    firstname: "Rois",
    lastname: "Osselton",
    dni: "07-191-5975",
    email: "rosseltoni@hud.gov",
    gender: "Female",
    phone: "512 511 3262",
    age: 32,
  },
  {
    id: 20,
    firstname: "Marguerite",
    lastname: "Koba",
    dni: "59-166-7875",
    email: "mkobaj@illinois.edu",
    gender: "Female",
    phone: "930 810 2792",
    age: 32,
  },
  {
    id: 21,
    firstname: "Izabel",
    lastname: "Alan",
    dni: "11-032-4289",
    email: "ialank@symantec.com",
    gender: "Female",
    phone: "174 609 0719",
    age: 42,
  },
  {
    id: 22,
    firstname: "Ronnie",
    lastname: "Eddisforth",
    dni: "93-095-1208",
    email: "reddisforthl@blogger.com",
    gender: "Male",
    phone: "749 733 5824",
    age: 34,
  },
  {
    id: 23,
    firstname: "Faun",
    lastname: "Jozsika",
    dni: "03-837-6032",
    email: "fjozsikam@msn.com",
    gender: "Female",
    phone: "220 571 3282",
    age: 27,
  },
  {
    id: 24,
    firstname: "Merrill",
    lastname: "Petruska",
    dni: "16-816-0753",
    email: "mpetruskan@yellowpages.com",
    gender: "Male",
    phone: "690 106 5208",
    age: 55,
  },
  {
    id: 25,
    firstname: "Starla",
    lastname: "Pentelo",
    dni: "34-851-7338",
    email: "spenteloo@ning.com",
    gender: "Female",
    phone: "365 219 5715",
    age: 23,
  },
  {
    id: 26,
    firstname: "Susana",
    lastname: "Tregonna",
    dni: "01-217-6599",
    email: "stregonnap@odnoklassniki.ru",
    gender: "Female",
    phone: "458 273 5758",
    age: 16,
  },
  {
    id: 27,
    firstname: "Marcie",
    lastname: "Sellack",
    dni: "97-023-9237",
    email: "msellackq@google.es",
    gender: "Female",
    phone: "834 168 8337",
    age: 34,
  },
  {
    id: 28,
    firstname: "Grantham",
    lastname: "Duprey",
    dni: "81-346-2047",
    email: "gdupreyr@wsj.com",
    gender: "Genderfluid",
    phone: "144 582 6212",
    age: 34,
  },
  {
    id: 29,
    firstname: "Sylas",
    lastname: "Orae",
    dni: "21-733-2658",
    email: "soraes@redcross.org",
    gender: "Male",
    phone: "682 307 5585",
    age: 60,
  },
  {
    id: 30,
    firstname: "Raddie",
    lastname: "Haig",
    dni: "25-305-4270",
    email: "rhaigt@ustream.tv",
    gender: "Male",
    phone: "268 577 0306",
    age: 48,
  },
];

export const getCustomers = () => {
  return new Promise((resolve, reject) => {
    /* setTimeout(() => { */
    return resolve(mockCustomers);
    /* }, 2000) */
  });
};

export const filterCustomerById = (id) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      return resolve(
        mockCustomers.filter((customer) => customer.id === Number(id))
      );
    }, 2000);
  });
};

export default mockCustomers;