import axios from "axios";

export const HttpEditRateGateway = () => {
  return {
    updateRate: async (data) => {
      /* desestructurar la data */
      /* crear el body */
      /* Ejecutar el return con la petición */
      console.log("DATA EN GATEWAY: ", data);
    },
  };
};
