export const BarberShopDeletePresenter = (
  deleteBarberShopGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("BarberShopsDelete was rendered!");
    },
    deleteBarberShop(id) {
      const deleteBarberShopPromise =
        deleteBarberShopGateway.deleteBarberShop(id);

      deleteBarberShopPromise
        .then((data) => {
          viewHandlers.deleteBarberShopSuccess(data);
        })
        .catch((err) => {
          viewHandlers.deleteBarberShopError(err);
        });
    },
  };
};
