import "./styles.scss";
import React, { useEffect } from "react";
import CustomForm from "../../components/form/CustomForm";
import { Divider, Paper } from "@mui/material";
import { getOwnersCreatePresenter } from "../../infrastructure/presentation/presenterProvider";

const OwnerCreate = () => {
  const viewHandlers = {
    createOwnerSucces(data) {
      console.log("<<<<<CREATE OWNER DATA>>>>>", data.statusText);
    },
    createOwnerError(err) {
      console.log("<<<<<ERROR>>>>>", err);
    },
  };

  const presenter = getOwnersCreatePresenter(viewHandlers);

  const createOwner = (data) => {
    presenter.createOwner(data);
  };

  useEffect(() => {
    presenter.present();
  });

  return (
    <Paper elevation={8} className={"ownersCreate-form-background"}>
      <div className={"ownersCreate-title-container"}>
        <div className={"ownersCreate-form-title"}>Nuevo Dueño</div>
        <div>
          <Divider variant="middle" color={"black"} />
        </div>
      </div>
      <div className="ownersCreate-form-container">
        <CustomForm create={createOwner} />
      </div>
    </Paper>
  );
};

export default OwnerCreate;
