import axios from "axios";

export const HttpUpdateBarberGateway = () => {
  return {
    updateBarber: async (data) => {
      const { id, username, email, name, lastname, documentNumber } = data;
      const body = { id, username, email, name, lastname, documentNumber };
      return axios.put(`http://45.90.223.78/test/business/barber/update`, body);
    },
  };
};
