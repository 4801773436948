import axios from "axios";

export const HttpDeleteRateGateway = () => {
  return {
    deleteRate: async (id) => {
      return axios.delete(
        `http://45.90.223.78/test/business/rate/delete/id/${id}`
      );
    },
  };
};
