export const RegisterPresenter = (getRegisterGateway, viewHandlers) => {
  return {
    present() {
      console.log("Register was rendered!");
    },
    singIn(data) {
      const singInPromise = getRegisterGateway.registerUser(data);

      singInPromise
        .then((data) => {
          viewHandlers.singInSuccess(data);
        })
        .catch((err) => {
          viewHandlers.singInError(err);
        });
    },
  };
};
