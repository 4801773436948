import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
/* Material Components */
import {
  Box,
  Toolbar,
  Drawer,
  CssBaseline,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
/* Icons */
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AccountCircle } from "@mui/icons-material";

/* Drawer Components */
import {
  AppBar,
  DrawerHeader,
  drawerWidth,
  openedMixin,
  closedMixin,
} from "../components/DrawerComponents";

/* Context */
import UserContext from "../../../context";

/* Components */
import ListNested from "../components/ListNested";

const MiniDrawer = (props) => {
  const { children, window } = props;
  const { isLogged, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    console.log("Cerrando sesión...");
    logout();
    navigate("/");
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawerContent = (
    <>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Box sx={{ mt: 3 }} onClick={handleDrawerClose}>
        <ListNested />
      </Box>
    </>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {isLogged && (
        <>
          <AppBar position="fixed" open={open}>
            <Toolbar
              className={"appbar-inner-container"}
              style={{ minHeight: "75px" }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                TaxiBarber
              </Typography>
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  sx={{ ...(open && { display: "none" }) }}
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Settings</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>

          <Box
            component="nav"
            sx={{
              width: {
                sm: open ? drawerWidth : `calc(23% - ${drawerWidth}px)`,
              },
              flexShrink: { sm: 0 },
            }}
          >
            <Drawer
              container={container}
              variant="temporary"
              open={open}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawerContent}
            </Drawer>
            <Drawer
              variant="permanent"
              open={open}
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  ...(open && {
                    zIndex: theme.zIndex.AppBar + 1,
                    ...openedMixin(theme),
                    "& .MuiDrawer-paper": openedMixin(theme),
                  }),
                  ...(!open && {
                    ...closedMixin(theme),
                    "& .MuiDrawer-paper": closedMixin(theme),
                  }),
                },
              }}
            >
              {drawerContent}
            </Drawer>
          </Box>
        </>
      )}
      <div className={"drawer-content-container"}>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 5, mt: 2 }}
          className={!isLogged && "main-box"}
        >
          {isLogged && <DrawerHeader />}
          <div className={"children-container"}>{children}</div>
        </Box>
      </div>
    </Box>
  );
};

export default MiniDrawer;
