import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import UserContext from "../../context";

const PrivateRoute = ({ Component }) => {
  const { isLogged, verifySessionStatus } = useContext(UserContext);

  useEffect(() => {
    if (!verifySessionStatus()) {
      <Navigate to={"/"} />;
    }
  }, []);

  return isLogged ? <Component /> : <Navigate to={"/"} />;
};

export default PrivateRoute;
