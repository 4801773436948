import { Grid, IconButton, InputAdornment, Stack } from "@mui/material";
import "./styles.scss";
import React, { useState, useEffect } from "react";
import CustomTextField from "../../../../components/UI/CustomTextInput";
import { useForm } from "react-hook-form";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Box } from "@mui/system";
import { CustomButton } from "../../../../components/UI/CustomButton";
import { useNavigate } from "react-router-dom";

const CustomForm = ({ id, ownerObject, create, update }) => {
  const { handleSubmit, control, setValue, reset } = useForm();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    ownerObject === undefined
      ? create({ ...data, contract_date: new Date() })
      : update({ ...data, id: Number(id) });
    navigate("/owners/all");
  };

  const resetForm = () => {
    reset({});
  };

  useEffect(() => {
    if (ownerObject !== undefined) {
      setValue("fullname", ownerObject.fullname);
      setValue("dni", ownerObject.dni);
      setValue("email", ownerObject.email);
      setValue("password", ownerObject.password);
      setValue("comment", ownerObject.comment);
      setValue("address", ownerObject.address);
      setValue("locality", ownerObject.locality);
      setValue("province", ownerObject.province);
      setValue("country", ownerObject.country);
      setValue("commission", ownerObject.commission);
      setValue("phone", ownerObject.phone);
    }
  }, [setValue, ownerObject]);

  return (
    <div className="owners-form-main-container">
      <Box className="owners-grid-container">
        <Grid
          container
          spacing={{ sm: 4, xs: 4, md: 2 }}
          className={"owners-grid-form"}
        >
          {/* <Grid item xs={12} md={12} className={"grid-item"}>
            <div className={"date-container"}>
              {console.log(ownerObject.created_at)}
              <CustomDatePicker
                name="created_at"
                control={control}
                label="Fecha de Creación"
                value={ownerObject !== undefined && ownerObject.created_at}
              />
            </div>
          </Grid> */}
          <div className={"owners-form-inputs-container"}>
            <Grid item xs={12} md={12} className="grid-item">
              <CustomTextField
                name="fullname"
                control={control}
                label="Nombre y Apellido"
                value={ownerObject !== undefined ? ownerObject.fullname : ""}
                type={"text"}
                placeholder={"Ej. Juan Gomez"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className={"owners-form-inputs-container"}>
            <Grid item xs={12} md={12} className="grid-item">
              <CustomTextField
                name="dni"
                control={control}
                label="DNI"
                value={ownerObject !== undefined ? ownerObject.dni : ""}
                type={"number"}
                placeholder={"DNI sin puntos ni comas"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>
          </div>
          <div className={"owners-form-inputs-container"}>
            <Grid item xs={12} md={12} className="grid-item">
              <CustomTextField
                name="email"
                control={control}
                label="Email"
                value={ownerObject !== undefined ? ownerObject.email : ""}
                type={"email"}
                placeholder={"Ej. abc@abc.com"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className={"owners-form-inputs-container"}>
            <Grid item xs={12} md={12} className="grid-item">
              <CustomTextField
                name="password"
                control={control}
                label="Contraseña"
                value={ownerObject !== undefined ? ownerObject.password : ""}
                type={showPassword ? "text" : "password"}
                placeholder={"*********"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDown}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                rules={{
                  required: {
                    value: false,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className={"owners-form-inputs-container"}>
            <Grid item xs={12} md={12} className="grid-item">
              <CustomTextField
                name="comment"
                control={control}
                label="Comentario"
                value={ownerObject !== undefined ? ownerObject.comment : ""}
                type={"text"}
                placeholder={"Ej. Dueño"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className={"owners-form-inputs-container"}>
            <Grid item xs={12} md={12} className="grid-item">
              <CustomTextField
                name="address"
                control={control}
                label="Dirección"
                value={ownerObject !== undefined ? ownerObject.address : ""}
                type={"text"}
                placeholder={"Ej. Calle falsa 123"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className={"owners-form-inputs-container"}>
            <Grid item xs={12} md={12} className="grid-item">
              <CustomTextField
                name="locality"
                control={control}
                label="Localidad"
                value={ownerObject !== undefined ? ownerObject.locality : ""}
                type={"text"}
                placeholder={"Ej. Luján de Cuyo"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className={"owners-form-inputs-container"}>
            <Grid item xs={12} md={12} className="grid-item">
              <CustomTextField
                name="province"
                control={control}
                label="Provincia"
                value={ownerObject !== undefined ? ownerObject.province : ""}
                type={"text"}
                placeholder={"Ej. Mendoza"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className={"owners-form-inputs-container"}>
            <Grid item xs={12} md={12} className="grid-item">
              <CustomTextField
                name="country"
                control={control}
                label="País"
                value={ownerObject !== undefined ? ownerObject.country : ""}
                type={"text"}
                placeholder={"Ej. Argentina"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className={"owners-form-inputs-container"}>
            <Grid item xs={12} md={12} className="grid-item">
              <CustomTextField
                name="commission"
                control={control}
                label="Comisión"
                value={ownerObject !== undefined ? ownerObject.commission : ""}
                type={"number"}
                placeholder={"Porcentajes entre 1 y 100"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>
          </div>
          <div className={"owners-form-inputs-container"}>
            <Grid item xs={12} md={12} className="grid-item">
              <CustomTextField
                name="phone"
                control={control}
                label="Telefono"
                value={ownerObject !== undefined ? ownerObject.phone : ""}
                type={"number"}
                placeholder={"Código de area + Número. Ej: 54 9 261 8978993"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>
          </div>

          <Grid
            item
            xs={12}
            md={12}
            mb={3}
            className={"owners-form-buttons-container"}
          >
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "row",
              }}
              spacing={3}
              className="owners-form-buttons-stack"
            >
              <CustomButton
                onClick={handleSubmit(onSubmit)}
                variant={"text"}
                textColor={"black"}
              >
                Submit
              </CustomButton>
              <CustomButton
                onClick={resetForm}
                variant={"text"}
                textColor={"black"}
              >
                Reset
              </CustomButton>
            </Stack>
            <div className="onwers-form-back-button-container">
              <CustomButton
                onClick={() => navigate(-1)}
                variant="outlined"
                color="#FFC107"
                textColor={"black"}
              >
                Volver
              </CustomButton>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CustomForm;
