export const RatesDetailPresenter = (viewHandlers, getRateGateway) => {
  return {
    present() {
      console.log("RatesDetail was rendered!");
    },

    getRateById(id) {
      const getRatePromise = getRateGateway.getRate(id);

      getRatePromise
        .then((data) => {
          viewHandlers.getRateSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getRateError(err);
        });
    },
  };
};
