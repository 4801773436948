export const SellersHomePresenter = (getAllSellersGateway, viewHandlers) => {
  return {
    present() {
      console.log("SellersHome was rendered");
    },
    getAllSellers() {
      const getAllSellersPromise = getAllSellersGateway.getAllSellers();

      getAllSellersPromise
        .then((data) => {
          viewHandlers.getAllSellersSucces(data);
        })
        .catch((err) => {
          viewHandlers.getAllSellersError(err);
        });
    },
  };
};
