export const BarberShopsHomePresenter = (
  getAllBarberShopsGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("BarberShopsHome was rendered");
    },
    getAllBarberShops() {
      const getAllBarberShopsPromise =
        getAllBarberShopsGateway.getAllBarberShops();

      getAllBarberShopsPromise
        .then((data) => {
          viewHandlers.getAllBarberShopsSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getAllBarberShopsError(err);
        });
    },
  };
};
