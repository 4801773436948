export const RatesDeletePresenter = (deleteRateGateway, viewHandlers) => {
  return {
    present() {
      console.log("RatesDelete was rendered!");
    },
    deleteRate(id) {
      const deleteRatePromise = deleteRateGateway.deleteRate(id);

      deleteRatePromise
        .then((data) => {
          viewHandlers.deleteRateSuccess(data);
        })
        .catch((err) => {
          viewHandlers.deleteRateError(err);
        });
    },
  };
};
