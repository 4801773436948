import React, { useState, useEffect } from "react";
import "./styles.scss";
import DataTable from "../../../../components/DataTable";
import ActionButtons from "../../../../components/ActionButtons/index";
import { getOwnersHomePresenter } from "./../../infrastructure/presentation/presenterProvider";

const columns = [
  {
    field: "id",
    headerName: "Id",
    flex: 1,
    minWidth: 70,
    maxWidth: 120,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "fullname",
    headerName: "Nombre",
    flex: 1,
    minWidth: 200,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "comment",
    headerName: "Comentario",
    flex: 1,
    minWidth: 200,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 230,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "phone",
    headerName: "Teléfono",
    flex: 1,
    minWidth: 170,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "",
    headerName: "Acciones",
    flex: 1,
    minWidth: 180,
    headerClassName: "column-title",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <ActionButtons data={params} category={"owners"} title={"Dueños"} />
      );
    },
  },
];

function OwnersHome() {
  const [owners, setOwners] = useState([]);

  const viewHandlers = {
    getAllOwnersSucces(data) {
      console.log("<<<<<<DATA>>>>>>>", data.statusText);
      setOwners(data.data.data);
    },
    getAllOwnersError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getOwnersHomePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getAllOwners();
  }, []);

  return (
    <div className="General">
      <div className="ownersHome-screen-title">Dueños</div>

      <>
        <DataTable
          rows={owners}
          columns={columns}
          buttonTitle={"Agregar Dueño"}
          category={"owners"}
        />
      </>
    </div>
  );
}

export default OwnersHome;
