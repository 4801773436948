import axios from "axios";

export const HttpDeleteBarberShop = () => {
  return {
    deleteBarberShop: async (id) => {
      return axios.delete(
        `http://45.90.223.78/test/business/branch-office/delete/id/${id}`
      );
    },
  };
};
