import "./styles.scss";
import React, { useEffect } from "react";
import CustomForm from "../../components/CustomForm";
import { Divider, Paper } from "@mui/material";
import { getSellersCreatePresenter } from "./../../infrastructure/presentation/presenterProvider";

function SellerCreate() {
  const viewHandlers = {
    createSellerSucces(data) {
      console.log("<<<<<CREATE SELLER DATA>>>>>", data.statusText);
    },
    createSellerError(err) {
      console.log("<<<<<ERROR>>>>>", err);
    },
  };

  const presenter = getSellersCreatePresenter(viewHandlers);

  const createSeller = (data) => {
    presenter.createSeller(data);
  };

  useEffect(() => {
    presenter.present();
  });

  return (
    <Paper elevation={8} className={"sellersCreate-form-background"}>
      <div className={"sellersCreate-screen-title-container"}>
        <div className={"sellersCreate-form-title"}>Nuevo Vendedor</div>
        <div>
          <Divider variant="middle" color={"black"} />
        </div>
      </div>
      <div className="sellersCreate-form-container">
        <CustomForm create={createSeller} />
      </div>
    </Paper>
  );
}

export default SellerCreate;
