import StorefrontIcon from "@mui/icons-material/Storefront";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import GroupIcon from "@mui/icons-material/Group";
import StoreIcon from "@mui/icons-material/Store";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";

const navBarOptions = [
  {
    id: 1,
    title: "Barberías",
    icon: <StoreIcon />,
    url: "/barbershops/all",
  },
  {
    id: 2,
    title: "Facturación",
    icon: <ReceiptLongIcon />,
    url: "/billing",
  },
  {
    id: 3,
    title: "Dueños",
    icon: <PersonIcon />,
    url: "/owners/all",
  },
  {
    id: 4,
    title: "Tarifas",
    icon: <AttachMoneyIcon />,
    url: "/rates/all",
  },
  {
    id: 5,
    title: "Barberos",
    icon: <ContentCutIcon />,
    url: "/barbers/all",
  },
  {
    id: 6,
    title: "Vendedores",
    icon: <GroupIcon />,
    url: "/sellers/all",
  },
  {
    id: 7,
    title: "Clientes",
    icon: <GroupsIcon />,
    url: "/clients/all",
  },
];

export default navBarOptions;
