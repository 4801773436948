import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { GetBillsMocks } from "../../services/bills.services";
import BillsForm from "../../components/BillsForm";
import PieChart from "../../../../components/UI/PieChart/PieChart";
import BarChart from "../../../../components/UI/BarChart/BarChart";

const BillsDashboard = () => {
  const [bills, setBills] = useState([]);
  /* const [owner, setOwner] = useState({}); */

  useEffect(() => {
    GetBillsMocks()
      .then((data) => {
        setBills(data);
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  });

  return (
    <div className="bills-dashboard-main-container">
      <div className="bills-dashboard-screen-title-container">
        <div className="bills-dashboard-screen-title"> Facturación </div>
      </div>
      <div className="bills-dashboard-form-container">
        <BillsForm />
      </div>
      <Grid container spacing={2} className="bills-dashboard-grid-container">
        <Grid item xs={12} md={12} className="bills-dashboard-grid-item">
          <Card className="bills-dashboard-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div">
                Resumen Mensual
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} className="bills-dashboard-grid-item">
          <Card className="bills-dashboard-data-container" elevation={3}>
            <CardContent>
              <Typography variant="body1">
                <strong>Empresa:</strong> Recaudación mensual de taxiBarber
              </Typography>
              <Typography variant="body1">
                <strong>Barbería:</strong> suma de la recaudación mensual de
                todas las sucursales a nombre del dueño loggeado
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} className="bills-dashboard-grid-item">
          <Card className="bills-dashboard-data-container" elevation={3}>
            <CardContent>
              <Typography variant="body1">
                <strong>Empresa:</strong> Cantidad de dueños de barberías
                utilizando el software
              </Typography>
              <Typography variant="body1">
                <strong>Barbería:</strong> cantidad de barberos trabajando en
                esa sucursal
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} className="bills-dashboard-grid-item">
          <Card className="bills-dashboard-data-container" elevation={3}>
            <CardContent>
              <Typography variant="body1">
                <strong>Empresa:</strong>
              </Typography>
              <Typography variant="body1">
                <strong>Barbería:</strong> Tiempo promedio = total de la suma
                del "tiempo promedio" de todos los cortes
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} className="bills-dashboard-grid-item">
          <Card className="bills-dashboard-data-container" elevation={3}>
            <CardContent>
              <Typography variant="body1">
                <strong>Empresa:</strong> clientes empresa Gráfico de torta
                (quien pagó y quien no)
              </Typography>
              <Typography variant="body1">
                <strong>Barbería:</strong>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} className="bills-dashboard-grid-item">
          <Card className="bills-dashboard-data-container" elevation={3}>
            <CardContent>
              <Typography variant="body1">
                <strong>Empresa:</strong> Histórico recaudación mensual empresa
                (gráfico de barra)
              </Typography>
              <Typography variant="body1">
                <strong>Barbería:</strong> Histórico recaudación mensual
                barbería (gráfico de barra)
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} className="bills-dashboard-grid-item">
          <Card className="bills-dashboard-data-container" elevation={3}>
            <CardContent>
              <Typography variant="body1">
                <strong>Empresa:</strong>
              </Typography>
              <Typography variant="body1">
                <strong>Barbería:</strong>ganancia dueño = total de la ganancia
                descontando el porcentaje de los barberos
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default BillsDashboard;
