import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import navbarOptions from "../../../services/services.categories";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

const ListNested = () => {
  const navigate = useNavigate();
  const open = true;

  return (
    <>
      <List>
        <ListItem
          disablePadding
          sx={{ display: "block" }}
          onClick={() => navigate("/home")}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <Tooltip title="Home" placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <HomeIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Home" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>

        {navbarOptions.map((item) => (
          <ListItem disablePadding sx={{ display: "block" }} key={item.id}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate(item.url)}
            >
              <Tooltip title={item.title} placement="right">
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={item.title}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default ListNested;
