import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { WeekMock, ShiftsMocks } from "../../services/rates.services";
import CustomTextField from "../../../../components/UI/CustomTextInput";
import CustomSelect from "../../../../components/UI/CustomSelect";
import { CustomButton } from "../../../../components/UI/CustomButton";
import "./styles.scss";
import { Box, Stack } from "@mui/system";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const RatesForm = ({ id, barberShops, create, edit, updRate }) => {
  /* Acá falta recibir una tarifa por prop, y setear los valores que trae, dentro del formulario para poder editar */
  const { handleSubmit, control, setValue, reset } = useForm();
  const navigate = useNavigate();

  const [rate, setRate] = useState({});

  /* useEffect(() => {
    GetAllRates()
      .then((data) => {
        data.map((item) => {
          if (item.id === id) {
            return setRate(item);
          }
        });
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
    setValue("branch_office_id", rate.branch_office_id);
    setValue("dayWeek", rate.dayWeek);
    setValue("partDay", rate.partDay);
    setValue("typeCut", rate.typeCut);
    setValue("flag", rate.flag);
    setValue("intervalPrice", rate.intervalPrice);
    setValue("maximum", rate.maximum); */
  /* setValue("comments", rate.comments) */
  /* }, [rate]); */

  const onSubmit = (data) => {
    updRate === undefined ? create(data) : edit(data);
    navigate("/rates/all");
  };

  const resetForm = () => {
    reset({});
  };

  return (
    <div className="rates-form-main-container">
      <Box className="rates-form-grid-container">
        <Grid container spacing={2} className={"rates-form-grid"}>
          <Grid container item spacing={2} className="rates-inner-grid">
            <Grid item xs={12} md={12} className="rates-inner-grid-item">
              <div className="inner-grid-select-container">
                <CustomSelect
                  name="branch_office_id"
                  control={control}
                  label="Sucursal"
                  options={barberShops}
                  rules={{
                    required: {
                      value: true,
                      message: "Campo obligatorio",
                    },
                  }}
                />
              </div>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={12}
              className="rates-inner-grid-item"
            >
              <Grid item xs={12} md={6} className="mini-select-container">
                <div className="select-container">
                  <CustomSelect
                    name="dayWeek"
                    control={control}
                    label="Día"
                    options={WeekMock}
                    rules={{
                      required: {
                        value: true,
                        message: "Campo obligatorio",
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="mini-select-container">
                <div className="select-container">
                  <CustomSelect
                    name="partDay"
                    control={control}
                    label="Turno"
                    options={ShiftsMocks}
                    rules={{
                      required: {
                        value: true,
                        message: "Campo obligatorio",
                      },
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div className="rates-form-inputs-container">
            <Grid item xs={12} md={12} className={"grid-item"}>
              <CustomTextField
                name="typeCut"
                control={control}
                label="Tipo de trabajo"
                value={rate !== undefined ? rate.typeCut : ""}
                type={"text"}
                placeholder={"Corte y barba"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="rates-form-inputs-container">
            <Grid item xs={12} md={12} className={"grid-item"}>
              <CustomTextField
                name="flag"
                control={control}
                label="Bajada de bandera"
                value={rate !== undefined ? rate.flag : ""}
                type={"number"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="rates-form-inputs-container">
            <Grid item xs={12} md={12} className={"grid-item"}>
              <CustomTextField
                name="intervalPrice"
                control={control}
                label="Precio del intervalo"
                value={rate !== undefined ? rate.intervalPrice : ""}
                type={"number"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="rates-form-inputs-container">
            <Grid item xs={12} md={12} className={"grid-item"}>
              <CustomTextField
                name="maximum"
                control={control}
                label="Precio máximo"
                value={rate !== undefined ? rate.maximum : ""}
                type={"number"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>
          </div>
          <div className="rates-form-inputs-container">
            <Grid item xs={12} md={12} className={"grid-item"}>
              <CustomTextField
                name="comments"
                control={control}
                label="Comentarios"
                value={rate !== undefined ? rate.commets : ""}
                type={"text"}
                multiline={true}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
          </div>

          <Grid
            item
            xs={12}
            md={12}
            mb={3}
            className={"rates-buttons-container"}
          >
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "row",
              }}
              spacing={3}
              className="rates-buttons-stack"
            >
              <CustomButton
                onClick={handleSubmit(onSubmit)}
                variant={"text"}
                textColor={"black"}
              >
                Submit
              </CustomButton>
              <CustomButton
                onClick={resetForm}
                variant={"text"}
                textColor={"black"}
              >
                Reset
              </CustomButton>
            </Stack>
            <div className="rates-back-button-container">
              <CustomButton
                onClick={() => navigate(-1)}
                variant="outlined"
                color="#FFC107"
                textColor={"black"}
              >
                Volver
              </CustomButton>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default RatesForm;
