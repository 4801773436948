import React from "react";
import "./styles.scss";
import logo from "../../../../assets/images/logo.png";
import taxibarber from "../../../../assets/images/taxibarber.png";
import logo3d from "../../../../assets/images/tb3dd.png";
import { Divider, Grid } from "@mui/material";

const LoginHeader = () => {
  return (
    <div className="lh-main-container">
      <Grid container spacing={2} className={"grid-logo-container"}>
        <Grid item xs={12} md={12} className="logo">
          <div className="d-flex flex-column align-items-center">
            <img
              src={logo3d}
              alt=""
              className="newLogo"
            />
          </div>
        </Grid>
      </Grid>
      <Divider
        variant="middle"
        color="#FFC107"
        sx={{ marginTop: "2%", marginBottom: "1%" }}
      />
    </div>
  );
};

export default LoginHeader;

/* <img src={logo} alt={"taxibarber"} className="imgLogo" />
            <img src={taxibarber} alt="" className="imgTaxiBarber" /> */
