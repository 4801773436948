import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./styles.scss";
import { Typography, Grid, Card, CardContent } from "@mui/material";
import { CustomButton } from "../../../../components/UI/CustomButton";
import { useNavigate } from "react-router-dom";
import { getSellersDetailPresenter } from "../../infrastructure/presentation/presenterProvider";

const SellerDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [seller, setSellers] = useState({});

  const viewHandlers = {
    getSellerSucces(data) {
      setSellers(data.data.data);
      console.log("seller: ", seller);
    },
    getSellerError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getSellersDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
  }, []);

  useEffect(() => {
    presenter.getSeller(id);
  }, [id]);

  return (
    <div className="sellers-detail-main-screen">
      <div className="sellers-detail-screen-title-container">
        <div className="sellers-detail-screen-title">
          <Typography variant="h2" gutterBottom component="div">
            Detalle Vendedor
          </Typography>
        </div>
      </div>
      <Grid container spacing={2} className="sellers-detail-grid-container">
        <Grid item xs={12} md={8} className="sellers-detail-grid-item">
          <Card className="sellers-detail-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Nombre Completo
              </Typography>
              <Typography variant="body1">{`${seller.name} ${seller.lastname}`}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} className="sellers-detail-grid-item">
          <Card className="sellers-detail-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                DNI
              </Typography>
              <Typography variant="body1">{seller.documentNumber}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} className="sellers-detail-grid-item">
          <Card className="sellers-detail-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Nombre de usuario
              </Typography>
              <Typography variant="body1">{seller.username}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={8} className="sellers-detail-grid-item">
          <Card className="sellers-detail-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                E-mail
              </Typography>
              <Typography variant="body1">{seller.email}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} className="sellers-detail-grid-item">
          <Card className="sellers-detail-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Teléfono
              </Typography>
              <Typography variant="body1">{seller.phone}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} className="sellers-detail-grid-item">
          <Card className="sellers-detail-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Vendedor desde
              </Typography>
              <Typography variant="body1">
                {new Date(seller.created_at).toLocaleDateString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div className="sellers-detail-back-button-container">
        <CustomButton
          onClick={() => navigate(-1)}
          variant="outlined"
          color="#FFC107"
          textColor={"black"}
        >
          Volver
        </CustomButton>
      </div>
    </div>
  );
};

export default SellerDetail;
