import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import "./styles.scss";
import { Bar } from "react-chartjs-2";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { Box } from "@mui/system";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function BarChart({ title, labels, scores, bgColor }) {
  const data = useMemo(
    function () {
      return {
        labels: labels,
        datasets: [
          {
            label: "Cantidad",
            data: scores,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(90, 195, 123, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(90, 195, 123, 1)",
            ],
            borderWidth: 1,
            alignItems: "center",
          },
        ],
      };
    },
    [labels, scores]
  );

  const options = {
    title: {
      display: true,
      text: title,
    },
    fill: true,
    animations: true,
    scales: {
      y: {
        min: 0,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="bar-chart-main-container">
      <Card className="bar-chart-card-container">
        <CardHeader
          title={title}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: bgColor ? bgColor : "",
          }}
        />
        <Divider />
        <CardContent
          sx={{ backgroundColor: bgColor ? "rgba(254,231,159, 0.25)" : "" }}
        >
          <Box sx={{ height: "250px" }}>
            <Bar data={data} options={options} width={300} height={250} />
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}
