export const BarberDeletePresenter = (deleteBarberGateway, viewHandlers) => {
  return {
    present() {
      console.log("BarberDelete was rendered!");
    },
    deleteBarber(id) {
      const deleteBarberPromise = deleteBarberGateway.deleteBarber(id);
      
      deleteBarberPromise
      .then((data) => {
        viewHandlers.deleteBarberSucces(data);
      })
      .catch((err) => {
        viewHandlers.deleteBarberError(err);
      });
    },
  };
};
