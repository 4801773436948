export const WeekMock = [
  {
    id: 7,
    title: "Todos los días",
    value: 7,
  },
  {
    id: 1,
    title: "Lunes",
    value: 1,
  },
  {
    id: 2,
    title: "Martes",
    value: 2,
  },
  {
    id: 3,
    title: "Miércoles",
    value: 3,
  },
  {
    id: 4,
    title: "Jueves",
    value: 4,
  },
  {
    id: 5,
    title: "Viernes",
    value: 5,
  },
  {
    id: 6,
    title: "Sábado",
    value: 6,
  },
  {
    id: 0,
    title: "Domingo",
    value: 0,
  },
];

export const ShiftsMocks = [
  {
    id: 1,
    title: "Mañana",
    value: 1,
  },
  {
    id: 2,
    title: "Tarde",
    value: 2,
  },
  {
    id: 3,
    title: "Mañana y tarde",
    value: 3,
  },
];

export const RatesMock = [
  {
    id: "111",
    day: "Lunes",
    shift: "Mañana",
    type: "Corte",
    flag: 300,
    interval: 60,
    interval_price: 12,
    max_price: 950,
  },
  {
    id: "112",
    day: "Martes",
    shift: "Tarde",
    type: "Barba",
    flag: 300,
    interval: 60,
    interval_price: 12,
    max_price: 950,
  },
  {
    id: "113",
    day: "Miércoles",
    shift: "Mañana y tarde",
    type: "Pelo y Barba",
    flag: 300,
    interval: 60,
    interval_price: 12,
    max_price: 950,
  },
  {
    id: "114",
    day: "Jueves",
    shift: "Mañana",
    type: "Diseño",
    flag: 300,
    interval: 60,
    interval_price: 12,
    max_price: 950,
  },
  {
    id: "115",
    day: "Viernes",
    shift: "Tarde",
    type: "Corte",
    flag: 300,
    interval: 60,
    interval_price: 12,
    max_price: 950,
  },
  {
    id: "116",
    day: "Sábado",
    shift: "Mañana y tarde",
    type: "Barba",
    flag: 300,
    interval: 60,
    interval_price: 12,
    max_price: 950,
  },
  {
    id: "117",
    day: "Domingo",
    shift: "Mañana",
    type: "Diseño",
    flag: 300,
    interval: 60,
    interval_price: 12,
    max_price: 950,
  },
];

export const GetAllRates = () => {
  return new Promise((resolve, reject) => {
    return resolve(RatesMock);
  });
};

export const GetRateById = (id) => {
  return new Promise((resolve, reject) => {
    return resolve(RatesMock.filter((rate) => rate.id === id));
  });
};
