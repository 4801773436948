import React from "react";
import BarChart from "../../../components/UI/BarChart/BarChart";
import PieChart from "../../../components/UI/PieChart/PieChart";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import "./styles.scss";

const Home = () => {
  return (
    <div className="homeDashboard-main-container">
      <div className="homeDashboard-screen-title-container">
        <div className="homeDashboard-screen-title">Resumen Diario</div>
      </div>
      <Grid container spacing={2} className="homeDashboard-main-grid">
        <Grid item xs={12} md={6} className="homeDashboard-grid-item">
          <Card className="homeDashboard-data-container" elevation={3}>
            <CardContent>
              <BarChart
                title={"Cortes por día"}
                labels={[
                  "lunes",
                  "martes",
                  "miércoles",
                  "jueves",
                  "viernes",
                  "sábado",
                ]}
                scores={[55, 75, 60, 50, 70, 80]}
                /* bgColor="#ebad071a" */
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} className="homeDashboard-grid-item">
          <Card className="homeDashboard-data-container" elevation={3}>
            <CardContent>
              <PieChart
                title={"Cortes por día"}
                labels={[
                  "lunes",
                  "martes",
                  "miércoles",
                  "jueves",
                  "viernes",
                  "sábado",
                ]}
                scores={[55, 75, 60, 50, 70, 80]}
                /* bgColor="#ebad071a" */
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} className="homeDashboard-grid-item">
          <Card className="homeDashboard-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Datos clientes, sillones
              </Typography>
              <Typography variant="body1">55</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} className="homeDashboard-grid-item">
          <Card className="homeDashboard-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Datos cantidad de barberos
              </Typography>
              <Typography variant="body1">15</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} className="homeDashboard-grid-item">
          <Card className="homeDashboard-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Datos cantidad de vendedores
              </Typography>
              <Typography variant="body1">10</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
