export const BarberShopsDetailPresenter = (
  getBarberShopGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("BarberShopsDetail was rendered");
    },
    getBarberShopById(id) {
      const getBarberShopPromise = getBarberShopGateway.getBarberShop(id);

      getBarberShopPromise
        .then((data) => {
          viewHandlers.getBarberShopSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getBarberShopError(err);
        });
    },
  };
};
