import React from "react";
import "./styles.scss";

function Loader() {
  return (
    <div className="loader">
      <span className="hour"></span>
      <span className="min"></span>
      <span className="circel"></span>
    </div>
  );
}

export default Loader;
