import React, { useState } from "react";
import "./styles.scss";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Box, Stack } from "@mui/system";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import CustomTextField from "../../../components/UI/CustomTextInput";
import { CustomButton } from "../../../components/UI/CustomButton";
import LoginHeader from "../../Login/components/LoginHeader";

const RegisterForm = ({ presenter }) => {
  const { handleSubmit, control } = useForm();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    console.log("data: ", data);
    presenter.singIn(data);
  };

  return (
    <div className="registerForm-main-container">
      <LoginHeader />

      <div className="register-inputs-main-container">
        <div className="register-title-container">
          <div className="register-page-title">Crear cuenta</div>
        </div>
        <Box className="register-grid-container">
          <Grid container spacing={2} className="register-grid-inputs">
            <Grid item xs={12} md={12} className="register-grid-item">
              <CustomTextField
                name="name"
                control={control}
                label={"Nombre"}
                type={"text"}
                placeholder={"Nombre de pila"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className="register-grid-item">
              <CustomTextField
                name="lastname"
                control={control}
                label={"Apellido"}
                type={"text"}
                placeholder={"apellido"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className="register-grid-item">
              <CustomTextField
                name="documentNumber"
                control={control}
                label={"Número de documento"}
                type={"number"}
                placeholder={"00000000"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Solo se aceptan números",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className="register-grid-item">
              <CustomTextField
                name="email"
                control={control}
                label={"Email"}
                type={"email"}
                placeholder={"email@example.com"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
                    message: "Ingrese una dirección de correo válida",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className="register-grid-item">
              <CustomTextField
                name="password"
                control={control}
                label="Contraseña"
                type={showPassword ? "text" : "password"}
                placeholder={"********"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDown}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  minLength: {
                    value: 8,
                    message: "La contraseña debe tener al menos 6 caracteres",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="register-buttons-container">
        <Stack direction="row" spacing={6} className="register-buttons-stack">
          <CustomButton
            onClick={handleSubmit(onSubmit)}
            variant="outlined"
            color="#FFC107"
            textColor={"black"}
          >
            Crear cuenta
          </CustomButton>
        </Stack>
        <div className="back-btn-container">
          <CustomButton variant={"text"} onClick={() => navigate(-1)}>
            <div className="text-back-btn">Volver</div>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
