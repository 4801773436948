import React, { useEffect, useContext } from "react";
import "./styles.scss";
import BarberForm from "../../components/LoginForms/BarberForm";
import UserContext from "../../../../context";
import { useNavigate } from "react-router-dom";
import { getLoginPresenter } from "../../infrastructure/presentation/presenterProvider";
import { CustomButton } from "../../../../components/UI/CustomButton";

const BarbersLogin = () => {
  const { login, isLoading, isLogged, hasLoginError } = useContext(UserContext);
  const navigate = useNavigate();

  const viewHanlders = {
    loginSuccess(data) {
      console.log("<<<<<<LOGIN>>>>>>>", data.data.message);
      login(data.data);
    },
    loginError(err) {
      console.log("<<<<<<LOGIN ERROR>>>>>>>", err);
      login(err);
    },
  };

  const presenter = getLoginPresenter(viewHanlders);

  const handleLogin = (data) => {
    presenter.logIn(data);
  };

  useEffect(() => {
    presenter.present();
  }, []);

  useEffect(() => {
    if (isLogged) navigate("/home");
  }, [isLogged, navigate]);

  return (
    <div className="login-form-container">
      <BarberForm
        handleLogin={handleLogin}
        loading={isLoading}
        hasLoginError={hasLoginError}
      />
      <div className="login-back-button-container">
        <CustomButton
          onClick={() => navigate(-1)}
          variant="text"
          color="#FFC107"
          textColor={"black"}
        >
          Volver
        </CustomButton>
      </div>
    </div>
  );
};

export default BarbersLogin;
