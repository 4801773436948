import { Box, Grid } from "@mui/material";
import React from "react";
import "./styles.scss";
import { useForm } from "react-hook-form";
import CustomDatePicker from "../../../../components/UI/DatePicker/CustomDatePicker";
import { CustomButton } from "../../../../components/UI/CustomButton";
import CustomSelect from "../../../../components/UI/CustomSelect";

const selectOptions = [
  {
    title: "idrayton0",
    value: "idrayton0",
  },
  {
    title: "nlondesborough1",
    value: "nlondesborough1",
  },
  {
    title: "tfauguel2",
    value: "tfauguel2",
  },
  {
    title: "ajaukovic3",
    value: "ajaukovic3",
  },
  {
    title: "cstickney4",
    value: "cstickney4",
  },
  {
    title: "jkopman5",
    value: "jkopman5",
  },
  {
    title: "lbegbie6",
    value: "lbegbie6",
  },
  {
    title: "fpascoe7",
    value: "fpascoe7",
  },
  {
    title: "gcovet8",
    value: "gcovet8",
  },
  {
    title: "seisikovitsh9",
    value: "seisikovitsh9",
  },
];

const BillsForm = () => {
  const { handleSubmit, control, getValues, clearErrors, reset } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className="bills-form-main-container">
      <Box className="bills-select-container">
        <Grid container className="select-grid-container">
          <Grid item xs={12} md={12} className="grid-select-item">
            <CustomSelect
              name="owner"
              control={control}
              label="Dueño"
              options={selectOptions}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <div className="date-range-title">
        Seleccione rango de fechas para filtrar la información mensualmente
      </div>
      <Box className="bills-grid-container">
        <Grid container spacing={2} className="bills-form-grid">
          <Grid item xs={12} md={6} mb={2} className="bills-form-grid-item">
            <CustomDatePicker
              name="from_date"
              disabled={false}
              control={control}
              label="Desde"
              value={undefined}
              defaultValue={null}
              clearErrors={clearErrors}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
                validate: (value) =>
                  value <= getValues("to_date") || "Rango ingresado no válido",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} mb={2} className="bills-form-grid-item">
            <CustomDatePicker
              name="to_date"
              disabled={false}
              control={control}
              label="Hasta"
              value={undefined}
              defaultValue={null}
              clearErrors={clearErrors}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
                validate: (value) =>
                  value >= getValues("from_date") ||
                  "Rango ingresado no válido",
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} className="grid-button-container">
            <div className="grid-button">
              <CustomButton
                onClick={handleSubmit(onSubmit)}
                variant="text"
                color="#FFC107"
                textColor={"black"}
              >
                Buscar
              </CustomButton>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BillsForm;
