import React, { useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./sytles.scss";

import { Pie } from "react-chartjs-2";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { Box } from "@mui/system";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PieChart({ title, labels, scores, bgColor }) {
  const data = useMemo(
    function () {
      return {
        labels: labels,
        datasets: [
          {
            data: scores,
            backgroundColor: [
              "rgba(54, 162, 235, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(255, 206, 86, 1)",
            ],
            borderColor: [
              "rgba(54, 162, 235, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(255, 206, 86, 1)",
            ],
            borderWidth: 1,
            alignItems: "center",
          },
        ],
      };
    },
    [labels, scores]
  );

  const options = {
    title: {
      display: true,
      text: title,
    },
    fill: true,
    animations: true,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="pie-chart-main-container">
      <Card className="pie-chart-card">
        <CardHeader
          title={title}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: bgColor ? bgColor : "",
          }}
        />
        <Divider />
        <CardContent
          sx={{ backgroundColor: bgColor ? "rgba(254,231,159, 0.25)" : "" }}
        >
          <Box sx={{ height: "250px" }}>
            <Pie data={data} options={options} width={300} height={250} />
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}
