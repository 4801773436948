import axios from "axios";

export const HttpGetAllBarberShopsGateway = () => {
  return {
    getAllBarberShops: async () => {
      return axios.get(
        `http://45.90.223.78/test/business/branch-office/get-all`
      );
    },
  };
};
