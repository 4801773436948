import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { mockCustomers, getCustomers } from "../../services/customers.services";
import PieChart from "./../../../../components/UI/PieChart/PieChart";
import BarChart from "./../../../../components/UI/BarChart/BarChart";
/* import LineChart from "./../../../../components/UI/LineChart/LineChart"; */
import { CustomButton } from "../../../../components/UI/CustomButton";
import { useNavigate } from "react-router-dom";

const CustomersHome = () => {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  // const [customersData, setCustomersData] = useState([]);
  const [total, setTotal] = useState({ male: 0, female: 0 });
  const genderCounter = () => {
    const { male, female, other } = mockCustomers.reduce(
      (acc, customer) => {
        if (customer.gender === "Male") {
          acc.male++;
        }
        if (customer.gender === "Female") {
          acc.female++;
        }
        if (customer.gender === "Other") {
          acc.other++;
        }
        return acc;
      },
      { male: 0, female: 0, other: 0 }
    );
    setTotal({ male, female, other });
  };

  useEffect(() => {
    genderCounter();
    getCustomers()
      .then((data) => {
        console.log("data: ", data);
        setCustomers(data);
        // ageMap(data);
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  }, []);

  // const ageMap = (data) => {
  //   const dataMaped = {
  //     labels: data.map((data) => data.age),
  //     dataSets: [
  //       {
  //         label: "Edad usuarios",
  //         data: data.map((data) => data.gender),
  //       },
  //     ],
  //   };
  //   console.log("data mapeada: ", dataMaped);
  //   setCustomersData(dataMaped);
  // };

  // console.log("customers: ", customers);
  return (
    <div className="customers-screen-main-container">
      <div className="customers-screen-title-container">
        <div className="customers-screen-title">Clientes de barberías</div>
      </div>
      <Grid container spacing={2} className="customers-home-grid-container">
        <Grid item xs={12} md={4} className="customers-home-grid-item">
          <Card className="customers-home-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Total de clientes
              </Typography>
              <Typography variant="body1">{customers.length}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} className="customers-home-grid-item">
          <Card className="customers-home-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Hombres
              </Typography>
              <Typography variant="body1">{total.male}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} className="customers-home-grid-item">
          <Card className="customers-home-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Mujeres
              </Typography>
              <Typography variant="body1">{total.female}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} className="customers-home-grid-item">
          <Card className="customers-home-data-container" elevation={3}>
            <CardContent>
              <BarChart
                title={"Cortes por día"}
                labels={[
                  "lunes",
                  "Martes",
                  "Miércoles",
                  "Jueves",
                  "Viernes",
                  "Sábado",
                ]}
                scores={[3000, 8000, 4000, 5000, 7000, 10000]}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} className="customers-home-grid-item">
          <Card className="customers-home-data-container" elevation={3}>
            <CardContent>
              <PieChart
                title={"Divisón por sexo"}
                labels={["Hombre", "Mujer", "Otro"]}
                scores={[total.male, total.female, total.other]}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div className="customers-home-back-button-container">
        <CustomButton
          onClick={() => navigate(-1)}
          variant="outlined"
          color="#FFC107"
          textColor={"black"}
        >
          Volver
        </CustomButton>
      </div>
    </div>
  );
};

export default CustomersHome;
