import { Divider, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import CustomForm from "../../components/form/CustomForm";
import { getOwnersEditPresenter } from "./../../infrastructure/presentation/presenterProvider";

const EditOwner = () => {
  const { id } = useParams();
  const [owner, setOwner] = useState({});

  const viewHandlers = {
    getOwnerSucces(data) {
      setOwner(data.data.data);
    },
    getOwnerError(err) {
      console.log("ERROR: ", err);
    },
    updateOwnerSucces(data) {
      console.log("Updated Owner: ", data);
    },
    updateOwnerError(err) {
      console.log("ERROR: ", err);
    },
  };

  const presenter = getOwnersEditPresenter(viewHandlers);

  const editOwner = (data) => {
    presenter.updateOwner(data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getOwner(id);
  }, []);

  return (
    <Paper elevation={8} className={"owners-edit-form-background"}>
      <div className={"owners-edit-title-container"}>
        <div className={"owners-edit-screen-title"}>Editar Dueño</div>
        <div>
          <Divider variant="middle" color={"black"} />
        </div>
      </div>
      <div className="owners-edit-customForm-container">
        <CustomForm id={id} update={editOwner} ownerObject={owner} />
      </div>
    </Paper>
  );
};

export default EditOwner;
