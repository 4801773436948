import axios from "axios";

export const HttpDeleteOwnerGateway = () => {
  return {
    deleteOwner: async (id) => {
      return axios.delete(
        `http://45.90.223.78/test/business/owner/delete/id/${id}`
      );
    },
  };
};
