/* Gateways */
import { HttpLoginGateway } from "../gateways/HttpLoginGateway";

/* Presenters */
import { LoginPresenter } from "./LoginPresenter";

export const getLoginPresenter = (viewHandlers) => {
  const getLoginGateway = HttpLoginGateway();
  return LoginPresenter(getLoginGateway, viewHandlers);
};
