export const OwnersEditPresenter = (
  getOwnerGateway,
  updateOwnerGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("OwnersEdit was rendered");
    },
    getOwner(id) {
      const getOwnerPromise = getOwnerGateway.getOwner(id);

      getOwnerPromise
        .then((data) => {
          viewHandlers.getOwnerSucces(data);
        })
        .catch((err) => {
          viewHandlers.getOwnerError(err);
        });
    },
    updateOwner(data) {
      const updateOwnerPromise = updateOwnerGateway.updateOwner(data);

      updateOwnerPromise
        .then((data) => {
          viewHandlers.updateOwnerSucces(data);
        })
        .catch((err) => {
          viewHandlers.updateOwnerError(err);
        });
    },
  };
};
