export const SellersCreatePresenter = (createSellerGateway, viewHandlers) => {
  return {
    present() {
      console.log("SellersCreate was rendered");
    },
    createSeller(data) {
      const createSellerPromise = createSellerGateway.createSeller(data);

      createSellerPromise
        .then((data) => {
          viewHandlers.createSellerSucces(data);
        })
        .catch((err) => {
          viewHandlers.createSellerError(err);
        });
    },
  };
};
