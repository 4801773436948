import React, { useState, useEffect } from "react";
import "./styles.scss";
import BarberShopsForm from "../../components/form";
import { useParams } from "react-router-dom";
import { Divider, Paper } from "@mui/material";
import { getBarberShopUpdatePresenter } from "../../infrastructure/presentation/presenterProvider";

const BarberShopsEdit = () => {
  const { id } = useParams();
  const [barberShop, setBarberShop] = useState({});
  const [owners, setOwners] = useState([]);

  const viewHandlers = {
    getAllOwnersSuccess(data) {
      let ownersList = data.data.data;
      setOwners(
        ownersList.map((item) => ({
          value: item.id,
          title: item.fullname,
        }))
      );
    },
    getAllOwnersError(err) {
      console.log("<<<<<<ERROR OWNERS>>>>>>>", err);
    },
    getBarberShopUpdSuccess(data) {
      setBarberShop(data.data.data);
    },
    getBarberShopUpdError(err) {
      console.log("<<<<<<BARBERSHOP ERROR>>>>>>>", err);
    },
    updateBarberShopSuccess(data) {
      console.log("<<<<<<UPDATE BARBERSHOP DATA>>>>>>>", data.statusText);
    },
    updateBarberShopError(err) {
      console.log("<<<<<<UPDATE BARBERSHOP ERROR>>>>>>>", err);
    },
  };

  const presenter = getBarberShopUpdatePresenter(viewHandlers);

  const editBarberShop = (data) => {
    presenter.updateBarberShop(data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getAllOwners();
    presenter.getBarberShopUpd(id);
  }, []);

  return (
    <Paper elevation={8} className="barbershops-home-bg">
      <div className={"barbershops-edit-title-container"}>
        <div className={"barbershops-edit-screen-title"}>Editar Barbería</div>
        <div>
          <Divider variant="middle" color={"black"} />
        </div>
      </div>
      <div className="barbershops-form-container">
        <BarberShopsForm
          id={id}
          update={editBarberShop}
          barberShop={barberShop}
          owners={owners}
        />
      </div>
    </Paper>
  );
};

export default BarberShopsEdit;
