/* Gateways importadas */
import { HttpCreateRateGateway } from "../gateways/HttpCreateRateGateway";
import { HttpGetAllRates } from "../gateways/HttpGetAllRatesGateway";
import { HttpGetBarberShopsGateway } from "../gateways/HttpGetBarberShopsGateway";
import { HttpGetRateGateway } from "../gateways/HttpGetRateGateway";
import { HttpEditRateGateway } from "../gateways/HttpEditRateGateway";
import { HttpDeleteRateGateway } from "../gateways/HttpDeleteRateGateway";

/* Presenters importados */
import { FilteredRatesPresenter } from "./FilteredRatesPresenter";
import { RatesCreatePresenter } from "./RatesCreatePresenter";
import { RatesDetailPresenter } from "./RatesDetailPresenter";
import { RatesEditPresenter } from "./RatesEditPresenter";
import { RatesDeletePresenter } from "./RatesDeletePresenter";

/* Trae todas las tarifas */
export const getFilteredRatesPresenter = (viewHandlers) => {
  const getAllRatesGateway = HttpGetAllRates();
  return FilteredRatesPresenter(getAllRatesGateway, viewHandlers);
};

/* Trae una tarifa por el id - falta */
export const getRatesDetailPresenter = (viewHandlers) => {
  const getRateGateway = HttpGetRateGateway();
  return RatesDetailPresenter(getRateGateway, viewHandlers);
};

/* Crea una nueva tarifa */
export const getRatesCreatePresenter = (viewHandlers) => {
  const getBarberShopsGateway = HttpGetBarberShopsGateway();
  const createRateGateway = HttpCreateRateGateway();
  return RatesCreatePresenter(
    getBarberShopsGateway,
    createRateGateway,
    viewHandlers
  );
};

/* Edita una tarifa ya creada -falta traer la tarifa que llena el formulario */
export const getRatesEditPresenter = (viewHandlers) => {
  const editRateGateway = HttpEditRateGateway();
  const getRateGateway = HttpGetRateGateway();
  const getBarberShopsGateway = HttpGetBarberShopsGateway();
  return RatesEditPresenter(
    editRateGateway,
    getRateGateway,
    getBarberShopsGateway,
    viewHandlers
  );
};

/* Elimina una tarifa */
export const getRatesDeletePresenter = (viewHandlers) => {
  const deleteRateGateway = HttpDeleteRateGateway();
  return RatesDeletePresenter(deleteRateGateway, viewHandlers);
};
