import axios from "axios";

export const HttpUpdateSellerGateway = () => {
  return {
    updateSeller: async (data) => {
      const { id, name, lastname, documentNumber, email, password, username } =
        data;
      const body = {
        id: Number(id),
        name,
        lastname,
        documentNumber,
        email,
        password,
        username,
      };
      return axios.put("http://45.90.223.78/test/business/seller/update", body);
    },
  };
};
