import axios from "axios";

export const HttpLoginGateway = () => {
  return {
    logIn: async (data) => {
      const { email, password } = data;
      const body = {
        email,
        password,
      };
      return axios.post(`https://testing.backend.taxibarber.com/login`, body);
    },
  };
};
