import React, { useState, useEffect } from "react";
import "./styles.scss";
import BarberShopsForm from "../../components/form";
import { Divider, Paper } from "@mui/material";
import { getBarberShopCreatePresenter } from "../../infrastructure/presentation/presenterProvider";

const BarberShopsCreate = () => {
  const [owners, setOwners] = useState([]);

  const viewHandlers = {
    createBarberShopSuccess(data) {
      console.log("<<<<<<DATA>>>>>>>", data.statusText);
    },
    createBarberShopError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
    getAllOwnersSuccess(data) {
      let ownersList = data.data.data;
      setOwners(
        ownersList.map((item) => ({
          value: item.id,
          title: item.fullname,
        }))
      );
    },
    getAllOwnersError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getBarberShopCreatePresenter(viewHandlers);

  const createNewBarberShop = (data) => {
    presenter.createBarberShop(data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getAllOwners();
  }, []);

  return (
    <Paper elevation={10} className="barbershops-home-bg">
      <div className={"barbershops-home-title-container"}>
        <div className="barbershops-create-screen-title">Nueva Barbería</div>
        <div>
          <Divider variant="middle" color={"black"} />
        </div>
      </div>
      <div className="barbershops-form-container">
        <BarberShopsForm create={createNewBarberShop} owners={owners} />
      </div>
    </Paper>
  );
};

export default BarberShopsCreate;
