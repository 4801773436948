import React, { useState, useEffect } from "react";
import "./styles.scss";
import RatesForm from "../../components/RatesForm/form";
import { Divider, Paper } from "@mui/material";
import { getRatesCreatePresenter } from "../../infrastructure/presentation/presenterProvider";

const RatesCreate = () => {
  const [barberShops, setBarberShops] = useState([]);

  const viewHandlers = {
    getAllBarberShopsSuccess(data) {
      let barberShopsList = data.data.data;
      setBarberShops(
        barberShopsList.map((item) => ({
          value: item.id,
          title: item.name,
        }))
      );
    },
    getAllBarberShopsError(err) {
      console.log("<<<<<<ALL BARBERSHOPS ERROR>>>>>>>", err);
    },
    createRateSuccess(data) {
      console.log("<<<<<<CREATE RATE>>>>>>>", data.data.message);
    },
    createRateError(err) {
      console.log("<<<<<<CREATE RATE ERROR>>>>>>>", err);
    },
  };

  const presenter = getRatesCreatePresenter(viewHandlers);

  const createNewRate = (data) => {
    presenter.createRate(data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getAllBarberShops();
  }, []);

  return (
    <Paper elevation={8} className={"rates-create-form-container"}>
      <div className={"rates-create-title-container"}>
        <div className={"rates-create-screen-title"}>Nueva Tarifa</div>
        <div>
          <Divider variant="middle" color={"black"} />
        </div>
      </div>
      <div className="rates-create-form-container">
        <RatesForm create={createNewRate} barberShops={barberShops} />
      </div>
    </Paper>
  );
};

export default RatesCreate;
