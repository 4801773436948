import axios from "axios";

export const HttpCreateOwnerGateway = () => {
  return {
    createOwner: async (data) => {
      const {
        fullname,
        dni,
        email,
        password,
        comment,
        address,
        locality,
        province,
        country,
        commission,
        phone,
        contract_date,
      } = data;
      const body = {
        fullname,
        dni: Number(dni),
        email,
        password,
        comment,
        address,
        locality,
        province,
        country,
        commission: Number(commission),
        phone,
        contract_date,
      };
      // console.log(body);
      return axios.post("http://45.90.223.78/test/business/owner/create", body);
    },
  };
};
