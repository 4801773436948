import React, { useState, useEffect } from "react";
import "./styles.scss";
import { WeekMock, ShiftsMocks } from "../../services/rates.services";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../../../components/UI/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Grid } from "@mui/material";

const RatesHome = () => {
  const navigate = useNavigate();
  const [days, setDays] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [expandedChild, setExpandedChild] = useState(false);
  const [day, setDay] = useState();

  useEffect(() => {
    setDays(WeekMock);
    setShifts(ShiftsMocks);
  }, []);

  const handleParent = (panel) => (event, isExpanded) => {
    setDay(panel);
    setExpanded(isExpanded ? panel : false);
  };

  const handleChild = (panel) => (event, isChildExpanded) => {
    setExpandedChild(isChildExpanded ? panel : false);
    navigate(`/rates/filter/${day}/${panel}`);
  };

  return (
    <div className="rates-home-screen">
      <div className="rates-header">
        <Grid
          container
          spacing={{ xs: 4, sm: 4, md: 2 }}
          className={"rates-header-container"}
        >
          <Grid item xs={6} md={6} className={"rates-header-item"}>
            <div className={"rates-home-title"}>Tarifas</div>
          </Grid>
          <Grid item xs={6} md={6} className={"rates-header-item-button"}>
            <CustomButton
              onClick={() => navigate("/rates/add")}
              variant="text"
              color="#FFC107"
              icon={<AddIcon />}
              textColor={"black"}
            >
              Nueva Tarifa
            </CustomButton>
          </Grid>
        </Grid>
      </div>
      {days.map((day, key) => {
        return (
          <div className="day-accordion-container" key={key}>
            <Accordion
              className="parent-accordion-panel"
              expanded={expanded === day.id}
              onChange={handleParent(day.id)}
            >
              <AccordionSummary
                className="parent-accordion-titles"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="parentPanel-content"
                id="parentPanel-header"
              >
                <div className="parent-accordion-attribute-container">
                  {day.title}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {shifts.map((item, key) => {
                  return (
                    <div className="schedule-accordion-container" key={key}>
                      <Accordion
                        className="child-accordion-panel"
                        expanded={expandedChild === item.id}
                        onClick={handleChild(item.id)}
                      >
                        <AccordionSummary
                          className="child-accordion-titles"
                          expandIcon={<ChevronRightIcon />}
                          aria-controls="childPanel-content"
                          id="childPanel-content"
                        >
                          {item.title}
                        </AccordionSummary>
                      </Accordion>
                    </div>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};

export default RatesHome;
