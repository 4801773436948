export const OwnersCreatePresenter = (createOwnerGateway, viewHandlers) => {
  return {
    present() {
      console.log("OwnerCreate was rendered");
    },
    createOwner(data) {
      const createOwnerPromise = createOwnerGateway.createOwner(data);

      createOwnerPromise
        .then((data) => {
          viewHandlers.createOwnerSucces(data);
        })
        .catch((err) => {
          viewHandlers.createOwnerError(err);
        });
    },
  };
};
