import React, { useState, useEffect } from "react";
import "./styles.scss";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { FormHelperText } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Box, Stack } from "@mui/system";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import CustomTextField from "../../../../components/UI/CustomTextInput";
import { CustomButton } from "../../../../components/UI/CustomButton";
import LoginHeader from "../LoginHeader";

const BarberForm = ({ handleLogin, loading, hasLoginError }) => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const passwordRecovery = () => {
    console.log("A recuperar contraseña!");
  };

  const onSubmit = (data) => {
    handleLogin(data);
  };

  useEffect(() => {
    if (hasLoginError) {
      setError("email", {
        types: {
          invalid: "Error en las credenciales",
        },
      });
      setError("password", {
        types: {
          invalid: "Error en las credenciales",
        },
      });
    }
  }, [hasLoginError, setError]);

  return (
    <div className="login-form-main-container">
      <LoginHeader />
      <div className="login-inputs-container">
        <div className="login-title-container">
          <div className="login-page-title">Ingresar como barbero</div>
        </div>
        <Box className="login-grid-container">
          <Grid container spacing={2} className="login-inputs-grid">
            <Grid item xs={12} md={12} className="login-grid-item">
              <CustomTextField
                name="email"
                control={control}
                label={"Nombre de usuario o email"}
                type={"text"}
                placeHolder={"Username"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className="login-grid-item">
              <CustomTextField
                name="password"
                control={control}
                label={"Contraseña"}
                type={showPassword ? "text" : "password"}
                placeHolder={"Username"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDown}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
            {hasLoginError && errors.email && (
              <FormHelperText error={true}>
                {errors.email.types.invalid}
              </FormHelperText>
            )}
          </Grid>
        </Box>
      </div>
      <div className="buttons">
        <div className="login-btns-container">
          <Stack
            direction={{
              xs: "column-reverse",
              sm: "column-reverse",
              md: "row",
            }}
            spacing={3}
            className="buttons-stack"
          >
            <CustomButton
              onClick={() => navigate("/singin")}
              variant="outlined"
              color="#FFC107"
              textColor={"black"}
            >
              Registrarse
            </CustomButton>
            <CustomButton
              onClick={handleSubmit(onSubmit)}
              variant="outlined"
              color="#FFC107"
              textColor={"black"}
            >
              {loading ? "Cargando..." : "Ingresar"}
            </CustomButton>
          </Stack>
          <div className="forgotten-password-container">
            <CustomButton variant={"tiny"} onClick={() => passwordRecovery()}>
              <div className="forgotten-password-link">
                Olvidé mi contraseña
              </div>
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarberForm;
