import React from "react";
import "./styles.scss";

function ErrorScreen({ children }) {
  return (
    <div className="error-main-container">
      <div className="main-title">Error 404 - Page not found</div>
      <div>{children}</div>
    </div>
  );
}

export default ErrorScreen;
