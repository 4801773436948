import axios from "axios";

export const HttpGetRateGateway = () => {
  return {
    getRate: async (id) => {
      return axios.get(
        `http://45.90.223.78/test/business/rate/get-rates/id/${id}`
      );
    },
  };
};
