import React, { useEffect } from "react";
import "./styles.scss";
import RegisterForm from "../components/RegisterForm";
import { getRegisterPresenter } from "../infrastructure/presentation/presenterProvider";

const Register = () => {
  const viewHandlers = {
    singInSuccess(data) {
      console.log("<<<<<<REGISTER>>>>>>>", data.data.message);
    },
    singInError(err) {
      console.log("<<<<<<LOGIN ERROR>>>>>>>", err.response.data.message);
    },
  };

  const presenter = getRegisterPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
  }, []);

  return (
    <div className="register-main-container">
      <RegisterForm presenter={presenter} />
    </div>
  );
};

export default Register;
