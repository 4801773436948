import { Divider, Paper } from "@mui/material";
import React from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import CustomForm from "../../components/Form/CustomForm";
import { useEffect, useState } from "react";
import { getBarbersEditPresenter } from "./../../infrastructure/presentation/presenterProvider";

const EditBarber = () => {
  const { id } = useParams();
  const [barber, setBarber] = useState({});

  const viewHandlers = {
    getBarberByIdSucces(data) {
      setBarber(data.data.data);
    },
    getBarberByIdError(err) {
      console.log("ERROR: ", err);
    },
    updateBarberSuccess(data) {
      console.log("Updated Barber: ", data.statusText);
    },
    updateBarberError(err) {
      console.log("ERROR: ", err);
    },
  };

  const presenter = getBarbersEditPresenter(viewHandlers);

  const editBarber = (data) => {
    presenter.updateBarber(data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getBarberById(id);
  }, []);

  return (
    <Paper elevation={8} className={"barbers-edit-form-background"}>
      <div className={"edit-form-title-container"}>
        <div className={"barbers-edit-form-title"}>Editar Barbero</div>
      </div>
      <Divider variant="middle" color={"black"} />
      <div className="barbers-edit-customForm-container">
        <CustomForm id={id} update={editBarber} barbero={barber} />
      </div>
    </Paper>
  );
};

export default EditBarber;
