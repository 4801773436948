import * as React from "react";
import "./styles.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../CustomButton/index";

function CustomModal({ id, open, setOpen, category, title }) {
  const navigate = useNavigate();

  const deny = () => {
    setOpen(false);
  };

  const accept = () => {
    navigate(`/${category}/delete/${id}`);
  };

  return (
    <div className={"customModal-main-container"}>
      <Dialog
        disableAutoFocus
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={"customModal"}
      >
        <div className={"customModal-title-container"}>
          <DialogTitle id="alert-dialog-title" className={"customModal-title"}>
            Eliminar {title}
          </DialogTitle>
        </div>
        <div className={"customModal-content"}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Está seguro que desea eliminar definitivamente este registro?
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Luego de confirmar esta acción, no se podrá deshacer
            </DialogContentText>
          </DialogContent>
        </div>
        <div className={"customModal-buttons-container"}>
          <DialogActions>
            <CustomButton onClick={deny} variant={"text"} textColor={"black"}>
              Cancelar
            </CustomButton>
            <CustomButton onClick={accept} variant={"text"} textColor={"black"}>
              Confirmar
            </CustomButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

export default CustomModal;
