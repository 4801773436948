import axios from "axios";

export const HttpCreateSellerGateway = () => {
  return {
    createSeller: async (data) => {
      const { name, lastname, documentNumber, email, password } = data;
      const body = {
        name,
        lastname,
        documentNumber,
        email,
        password,
      };

      return axios.post(
        "http://45.90.223.78/test/business/seller/create",
        body
      );
    },
  };
};
