export const BarberShopUpdatePresenter = (
  updateBarberShopGateway,
  getBarberShopGateway,
  getAllOwnersGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("BarberShop Edit was rendered!");
    },
    getAllOwners() {
      const getAllOwnersPromise = getAllOwnersGateway.getAllOwners();

      getAllOwnersPromise
        .then((data) => {
          viewHandlers.getAllOwnersSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getAllOwnersError(err);
        });
    },
    getBarberShopUpd(id) {
      const getBarberShopUpdPromise = getBarberShopGateway.getBarberShop(id);

      getBarberShopUpdPromise
        .then((data) => {
          viewHandlers.getBarberShopUpdSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getBarberShopUpdError(err);
        });
    },
    updateBarberShop(data, id) {
      const updateBarberShopPromise = updateBarberShopGateway.updateBarberShop(
        data,
        id
      );

      updateBarberShopPromise
        .then((data) => {
          viewHandlers.updateBarberShopSuccess(data);
        })
        .catch((err) => {
          viewHandlers.updateBarberShopError(err);
        });
    },
  };
};
